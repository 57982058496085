import React from 'react';
import SingleModelSelector from '../MultipleModelsSelector/SingleModelSelector';
import { setParameters } from '../../redux/actions/parametersActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { resetVaccinesPredictions } from '../../redux/actions/vaccinesActions';

const VaccinesModelSelector = (props) => {
    const { vaccinesModel, setParameters, resetVaccinesPredictions } = props;
    // console.log('[VaccinesModelSelector] vaccinesModel', vaccinesModel);
    const updateSelectedModel = (_index, model, invalid, idIncomplete) => {
        const _model = { ...model, invalid, idIncomplete };
        // console.log('[VaccinesModelSelector] updateSelectedModel', _model);
        setParameters({ vaccinesModel: _model });
        resetVaccinesPredictions();
    };

    const updateVaccinesModelNumParams = (_index, value, type) => {
        const _model = { ...vaccinesModel, [type]: value };
        // console.log('[VaccinesModelSelector] updateVaccinesModelNumParams', _model);
        setParameters({ vaccinesModel: _model });
        resetVaccinesPredictions();
    };

    return <SingleModelSelector 
        updateSelectedModels={updateSelectedModel} 
        updateSelectedModelsNumParams={updateVaccinesModelNumParams} 
        {...vaccinesModel}
    />;
};



const mapStateToProps = (state) => {
    const { vaccinesModel, strainSubset } = state.parameters;
    return ({
        vaccinesModel,
        strainSubset,
        resetVaccinesPredictions
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesModelSelector);
