import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, MenuItem, Menu } from '@mui/material';
import { elementToSVG, inlineResources } from 'dom-to-svg';

import config from '../../config/envConfig';
import PDFDimensions from '../../components/Dialogs/PDFDimensions';
import {
    prepareUrl,
    pick,
    prepareParametersToExportUrl
} from '../../functions/functions';
import { getIgnoreStrainCutOffDateForColorBy } from '../../redux/selectors/metadataSelector';

/* ------------------------------------------------------------------
 *                              Utilities
 * -----------------------------------------------------------------*/

/**
 * Builds the output PDF filename based on parameters.
 */
const getFileNameWithParams = (props) => {
    const { filename, parameters } = props;

    const getParams = () => {
        switch (filename) {
            case 'antigenic':
                return pick('lineage', 'modelId', 'antigenicDataType', 'antigenicTiterType')(parameters);
            case 'strainTree':
                return pick('lineage', 'modelId', 'colorBy')(parameters);
            case 'frequencies':
                return pick('lineage', 'freqCategory', 'strainSubset')(parameters);
            case 'vaccines':
                return pick('lineage', 'vaccinesSerumType', 'strainsSubset')(parameters);
            default:
                return {};
        }
    };

    const paramsString = Object.values(getParams()).join('_');
    return `${filename}_${paramsString}.pdf`;
};

/**
 * If exporting antigenic data, we might have a table
 * whose dimensions we want to capture for the PDF.
 */
const getAntigenicDefaultDimn = (filename) => {
    const id = filename === 'antigenicDetails' ? '#table_details' : '#antigenic_table';
    const element = document.querySelector(id);

    if (!element) {
        return { height: 0, width: 0 };
    }

    const width = 400 + element.offsetWidth;
    const height = 200 + element.offsetHeight;

    return { height, width };
};

/* ------------------------------------------------------------------
 *                              Components
 * -----------------------------------------------------------------*/

/**
 * Renders a simple dialog to indicate that a PDF export is in progress.
 */
const PDFDialog = (props) => {
    const { pdfLoading } = props;
    return (
        <Dialog open={pdfLoading}>
            <DialogTitle id="pdf-dialog-title">PDF loading...</DialogTitle>
        </Dialog>
    );
};

/**
 * Main context menu component for exporting PDF/SVG or opening an edit tab.
 */
const ExportContextMenu = (props) => {
    const { filename, menuPos, menuOn, handleClose, parameters, alpha, rho, exportDimensions } =
    props;

    const [pdfLoading, setPdfLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    // --------------------------------------------------------------------------------------
    // PDF Export
    // --------------------------------------------------------------------------------------
    const getPDF = async (dimensions) => {
        const antigenicDefaults = getAntigenicDefaultDimn(filename);
        const isAntigenic = filename === 'antigenic' || filename === 'antigenicDetails';

        parameters.height = isAntigenic
            ? antigenicDefaults.height
            : parseInt(dimensions.height, 10) + 50;

        parameters.width = isAntigenic
            ? antigenicDefaults.width
            : parseInt(dimensions.width, 10) + 250;

        let url;
        if (filename === 'antigenicDetails') {
            url = prepareUrl(`${config.serverLink}/api/export/antigenic`, parameters || {});
            url += `&rho=${rho}&alpha=${alpha}`;
        } else {
            url = prepareUrl(`${config.serverLink}/api/export/${filename}`, parameters || {});
        }

        const response = await fetch(url, {
            headers: { Secret: config.REACT_APP_FRONTEND_SECRET_HEADER }
        });

        const buffer = await response.arrayBuffer();
        const blob = new Blob([buffer], { type: 'application/pdf' });

        // Create a link and trigger a download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = getFileNameWithParams(props);
        link.click();

        setPdfLoading(false);
    };

    const savePDF = (dimensions) => {
        setPdfLoading(true);
        handleCloseDialog();
        handleClose();
        return getPDF(dimensions);
    };

    const saveAsPDF = () => {
    // Some special handling for geomap / antigenic
        if (filename === 'geomap' || filename === 'antigenic')
            setPdfLoading(true);
            // setOpenDialog(true);
            // handleClose();
            // return;
        // }

        setOpenDialog(true);
        handleClose();
    };

    // --------------------------------------------------------------------------------------
    // Edit View
    // --------------------------------------------------------------------------------------
    const openEditView = async (dimensions) => {
        const isAntigenic = filename === 'antigenic' || filename === 'antigenicDetails';

        parameters.height = isAntigenic
            ? dimensions.height
            : parseInt(dimensions.height, 10) + 50;
        parameters.width = isAntigenic
            ? dimensions.width
            : parseInt(dimensions.width, 10) + 250;

        parameters.editMode = true;

        const url = prepareUrl(`${config.serverLink}/api/export/getLink`, parameters || {});
        const editUrl = await fetch(url, {
            headers: { Secret: config.REACT_APP_FRONTEND_SECRET_HEADER }
        })
            .then((response) => response.json())
            .then((data) => data.exportUrl);

        handleCloseDialog();
        parameters.editMode = false;

        window.open(editUrl);
    };

    // --------------------------------------------------------------------------------------
    // Close Helpers
    // --------------------------------------------------------------------------------------
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPdfLoading(false);
        handleClose();
    };

    // --------------------------------------------------------------------------------------
    // SVG Export (frequencies only)
    // --------------------------------------------------------------------------------------
    const handleSVGDownload = async () => {
        handleClose();
        const selector = 'frequencies_svg';

        // Convert an existing DOM node to an in-memory SVG doc
        const svgDocument = elementToSVG(document.getElementById(selector));
        await inlineResources(svgDocument.documentElement);

        // Serialize and offer as a download
        const svgString = new XMLSerializer().serializeToString(svgDocument);
        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);
        link.download = 'freqChart.svg';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // --------------------------------------------------------------------------------------
    // Render
    // --------------------------------------------------------------------------------------
    const { x, y } = menuPos;
    const dimn = { height: 800, width: 1100 };

    return (
        <>
            <PDFDialog pdfLoading={pdfLoading} />

            {/* Dialog for specifying PDF dimensions */}
            {openDialog && (
                <PDFDimensions
                    handleCloseDialog={handleCloseDialog}
                    filename={filename}
                    savePDF={savePDF}
                    exportDimensions={exportDimensions}
                    // openEditView={openEditView}
                />
            )}

            {/* Context Menu */}
            <Menu
                keepMounted
                open={menuOn}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={y !== null && x !== null ? { top: y, left: x } : undefined}
                sx={{
                    paper: {
                        borderRadius: '12px'
                    }
                }}
            >
                <MenuItem onClick={saveAsPDF}>Save as PDF</MenuItem>
                {filename === 'frequencies' && (
                    <MenuItem onClick={handleSVGDownload}>Save as SVG</MenuItem>
                )}
                {filename === 'strainTree' && (
                    <MenuItem onClick={() => openEditView(dimn)}>Open Edit Tab</MenuItem>
                )}
            </Menu>
        </>
    );
};

/* ------------------------------------------------------------------
 *                              Redux
 * -----------------------------------------------------------------*/
const mapStateToProps = (state) => {
    // Prepare parameters for the export URL
    const params = { ...state.parameters };
    const _parameters = prepareParametersToExportUrl(params);

    _parameters.ignoreStrainCutOffDate = getIgnoreStrainCutOffDateForColorBy(state);

    return {
        parameters: _parameters,
        alpha: state.antigenic.alpha,
        rho: state.antigenic.rho
    };
};

/* ------------------------------------------------------------------
 *                            Prop Types
 * -----------------------------------------------------------------*/
ExportContextMenu.propTypes = {
    menuOn: PropTypes.bool,
    menuPos: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    filename: PropTypes.string,
    handleClose: PropTypes.func,
    parameters: PropTypes.object,
    alpha: PropTypes.number,
    rho: PropTypes.number,
    exportDimensions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
    })
};

PDFDialog.propTypes = {
    pdfLoading: PropTypes.bool
};

/* ------------------------------------------------------------------
 *                         Export Default
 * -----------------------------------------------------------------*/
export default connect(mapStateToProps)(ExportContextMenu);
