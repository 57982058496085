import { merge, set } from 'lodash';
// Action Types
import {
    SET_RENDER_STATUS,
    SET_COMPONENT_STATUS,
    RESET_COMPONENTS_STATUS,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_STRAINS_TREE_DIMENSIONS,
    SET_LABEL_MOVEMENT,
    CORRECT_LABEL_POSITIONS,
    SET_SHOW_MUTATIONS_GROUPS,
    TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY,
    SET_MUTATION_CLASSES,
    SET_LAYOUT_PARAMETERS,
    SET_SHOW_CLADE_LABELS,
    ZOOM_TREE_SUCCESS,
    INIT_STRAIN_TREE_SUCCESS,
    FETCH_RECALCULATED_TREE_SUCCESS,
    FETCH_SUBSET_TREE_SUCCESS,
    FETCH_TREE_FREQS_SUCCESS,
    FETCH_SELECTED_STRAIN_SUCCESS,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS,
    FETCH_VP_VALUES_SUCCESS,
    FETCH_HUMAN_SEROLOGY_SUCCESS,
    FETCH_CUSTOM_TREE_ATTRS_SUCCESS,
    FETCH_GENOTYPE_DATA_SUCCESS,
    FETCH_MODEL_DATA_SUCCESS,
    FETCH_ANTIGENIC_MODEL_SUCCESS,
    SET_COLOR_BY,
    RESET_GENOTYPE_STATUS,
    SET_REFERENCE_STRAIN,
} from '../actions/actionTypes';

import { RENDER_STATUS } from '../../config/consts';
import { current, produce } from 'immer';

let renderInitialState = {};


export const setRenderInitialState = (state) => {
    renderInitialState = {
        ...state,
        hiddenMenu: window.innerWidth < 786 ? true : false,
    };
};

const clearLabels = (labels, deleteConditions) =>
    Object.keys(labels).reduce((acc, type) => {
        const deleteCondition = deleteConditions.reduce(
            (accCond, c) => accCond || (c.type === type && c.condition),
            false
        );
        // const deleteCondition = (type === 'mutationLabel' && (showMutationsGroups === false));
        if (!deleteCondition) {
            acc[type] = Object.keys(labels[type]).reduce(
                (acc_type, label_id) => {
                    acc_type[label_id] = {
                        ...labels[type][label_id],
                        xMod: 0,
                        yMod: 0,
                        initialized: false,
                    };
                    return acc_type;
                },
                {}
            );
        }
        return acc;
    }, {});

const calculateRenderStatus = (viewToRender) => {
    const statusList = Object.values(viewToRender.components);

    if (
        statusList.length === 0 ||
        statusList.every((status) => status === RENDER_STATUS.NONE)
    ) {
        return RENDER_STATUS.NONE;
    } else if (statusList.every((status) => status === RENDER_STATUS.DONE)) {
        return RENDER_STATUS.DONE;
    } else {
        return 'loading';
    }
};

export const renderStatusReducer = (state = renderInitialState, action) =>
    produce(state, (draft) => {
        // Store the initial cladeLabels status
        // const prevMutationsClassesStatus = state.viewToRender?.components?.mutationsClasses;
        switch (action.type) {
            case RESET_SESSION:
            case SIGNOUT_REQUEST: {
                return renderInitialState;
            }
            case SET_RENDER_STATUS: {
                const { renderStatus } = action.payload;
                draft.renderStatus = renderStatus;
                break;
            }
            case SET_COMPONENT_STATUS: {
                const { viewName, componentId, status, labels } = action.payload;
                if (draft.viewToRender.viewName === viewName) {
                    draft.viewToRender.components[componentId] = status;
                } else {
                    draft.viewToRender.viewName = viewName;
                    draft.viewToRender.components = {
                        [componentId]: status,
                    };
                }

                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                if (labels)
                    draft.labels = merge(draft.labels, { [labels.type]: labels.labels });

                // if (componentId === 'cladeLabels') console.log(`[renderStatusReducer] =>`, {viewName, componentId, status}, current(draft).viewToRender.components, current(draft).renderStatus);
                break;
            }
            case RESET_COMPONENTS_STATUS: {
                const { viewName, status } = action.payload;
             
                if (draft.viewToRender.viewName === viewName) {
                    Object.keys(draft.viewToRender.components).forEach((key) => {
                        draft.viewToRender.components[key] = status;
                    });
                }
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                // console.log(`[renderStatusReducer] => RESET_COMPONENTS_STATUS`, status);
                break;
            }
            case SET_STRAINS_TREE_DIMENSIONS: {

                Object.keys(draft.labels).forEach(type => {
                    Object.keys(draft.labels[type]).forEach(id => {
                        draft.labels[type][id] = {
                            ...draft.labels[type][id],
                            xMod: 0,
                            yMod: 0,
                            initialized: false,
                        };
                    });
                });

                if (draft.viewToRender.viewName === 'strainTree') {
                    Object.keys(draft.viewToRender.components).forEach((key) => {
                        draft.viewToRender.components[key] = RENDER_STATUS.NONE;
                    });
                }
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                break;
            }

            case SET_LAYOUT_PARAMETERS: {
                if (draft.viewToRender.viewName === 'strainTree') {
                    Object.keys(draft.viewToRender.components).forEach((key) => {
                        draft.viewToRender.components[key] = RENDER_STATUS.NONE;
                    });
                }
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                // console.log(`[renderStatusReducer] => SET_LAYOUT_PARAMETERS`);
                break;
            }

            case SET_COLOR_BY: {
                if (draft.viewToRender.viewName === 'strainTree') {
                    Object.keys(draft.viewToRender.components).forEach((key) => {
                        draft.viewToRender.components[key] = RENDER_STATUS.NONE;
                    });
                }
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                // console.log(`[renderStatusReducer] => SET_COLOR_BY`);
                break;
            }

            //clear reference strain
            case SET_REFERENCE_STRAIN: {
                const { refStrain } = action.payload;
                if (refStrain) break;
                if (draft.viewToRender.viewName === 'strainTree') {
                    Object.keys(draft.viewToRender.components).forEach((key) => {
                        draft.viewToRender.components[key] = RENDER_STATUS.NONE;
                    });
                }
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                // console.log(`[renderStatusReducer] => SET_REFERENCE_STRAIN`);
                break;
            }

            case SET_SHOW_MUTATIONS_GROUPS: {
                const { showMutationsGroups } = action.payload;
                draft.labels = clearLabels(draft.labels, [
                    {
                        type: 'mutationLabel',
                        condition: showMutationsGroups === false,
                    },
                ]);
         
                draft.viewToRender.components.mutationsClasses = RENDER_STATUS.NONE;
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                break;
            }

            case SET_SHOW_CLADE_LABELS: {
                const { showCladeLabels } = action.payload;
                if (showCladeLabels !== false)
                    draft.labels = clearLabels(draft.labels, [
                        {
                            type: 'cladeLabel',
                            condition: showCladeLabels === false,
                        },
                    ]);
                draft.viewToRender.components.cladeLabels = RENDER_STATUS.NONE;
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                break;
            }

            case TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY:
            case SET_MUTATION_CLASSES: {
                draft.viewToRender.components.mutationsClasses = RENDER_STATUS.NONE;
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                break;
            }

            case SET_LABEL_MOVEMENT: {
                const { id, type, subType, ...props } = action.payload;
                if (!draft.labels[type]) draft.labels[type] = {};
                const _id = `${id}${subType ? `_${subType}` : ''}`;
                draft.labels[type][_id] = { ...draft.labels[type][_id], ...props };
                break;
            }

            case CORRECT_LABEL_POSITIONS: {
                const labelNodes = action.payload;
                // console.log(labels);
                labelNodes
                //.filter(({ showLabel }) => (!showLabel === false))
                    .forEach(({ id, x, y, type, mutClass }) => {
                        const _id = mutClass ? `${id}_${mutClass}` : id;
                        //console.log(`type = ${type}, id = ${_id}`);
                        set(draft.labels, [type, _id, 'x'], x);
                        set(draft.labels, [type, _id, 'y'], y);
                    // labels[type][_id].x = x;
                    // labels[type][_id].y = y;
                    });
                break;
            }

            case ZOOM_TREE_SUCCESS:
            case INIT_STRAIN_TREE_SUCCESS:
            case FETCH_RECALCULATED_TREE_SUCCESS:
            case FETCH_SUBSET_TREE_SUCCESS:
            case FETCH_TREE_FREQS_SUCCESS:
            case FETCH_SELECTED_STRAIN_SUCCESS:
            case FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS:
            case FETCH_VP_VALUES_SUCCESS:
            case FETCH_HUMAN_SEROLOGY_SUCCESS:
            case FETCH_CUSTOM_TREE_ATTRS_SUCCESS:
            case FETCH_GENOTYPE_DATA_SUCCESS:
            case FETCH_MODEL_DATA_SUCCESS:
            case FETCH_ANTIGENIC_MODEL_SUCCESS: {
                if (draft.viewToRender?.viewName === 'strainTree')
                    draft.renderStatus = RENDER_STATUS.NONE;
                break;
            }
            case RESET_GENOTYPE_STATUS: {
                if (draft.viewToRender.viewName === 'strainTree') {
                    Object.keys(draft.viewToRender.components).forEach((key) => {
                        draft.viewToRender.components[key] = RENDER_STATUS.NONE;
                    });
                }
                draft.renderStatus = calculateRenderStatus(draft.viewToRender);
                break;
            }
            default:
                break;
        }

        // // Check at the end if cladeLabels became NONE
        // const nextMutationsClassesStatus = draft.viewToRender?.components?.mutationsClasses;
        // if (prevMutationsClassesStatus !== RENDER_STATUS.NONE && nextMutationsClassesStatus === RENDER_STATUS.NONE) {
        //     console.log('[renderStatusReducer] Mutations Classes set to NONE by:', {
        //         action: action.type,
        //         components: current(draft).viewToRender.components,
        //         renderStatus: current(draft).renderStatus
        //     });
        // }
    });

