import { select } from 'd3-selection';
import { isEqual } from 'lodash';
import { getScaledValue, getScale } from '../../functions/scales';
import { isFunction } from '../../functions/functions';
import { getNodeHeight, getNodeWidth } from '../../functions/cssHelpers';

class D3Component {
    componentName = '';

    props = null;

    mountNode = null;

    defaultPadding = { top: 0, bottom: 0, left: 0, right: 0 };
    padding = { ...this.defautPadding }
    defaultPaddingExport = { top: 0, bottom: 0, left: 0, right: 0 }
    paddingExport = { ...this.defautExportPadding }

    width = 0;

    height = 0;

    xScale = ''

    yScale = ''

    setComponentName = (name) => {
        this.componentName = name;
    }

    additionalPropsActions = () => {
        //
    }

    setProps = (props) => {
        this.props = props;
        this.additionalPropsActions(props);
    };

    setMountNode = (mountNode) => {
        this.mountNode = mountNode;
    };


    getPadding = () => {
        // console.log('[getPadding] 1', { componentName: this.componentName, multiexport: this.props?.multiexport, paddingMultiExport: this.paddingMultiExport });
        return (this.props && this.props.multiexport && this.paddingMultiExport)
        ? this.paddingMultiExport
        : (this.props && this.props.exportMode && this.paddingExport)
            ? this.paddingExport
            : this.padding;
    }

    setPadding = (padding) => {
        let paddingChanged = false;
        console.log('[setPadding] new padding', padding.right, 'old padding', this.padding.right);
        if (this.props.exportMode && this.paddingExport) {
            paddingChanged = !isEqual(this.paddingExport, padding)
            this.paddingExport = padding;
        } else {
            paddingChanged = !isEqual(this.padding, padding)
            this.padding = padding;

        }
        return paddingChanged;
    }

    resetPadding = () => {
        console.log('RESET PADDING');
        let paddingChanged = false;
        if (this.props.exportMode && this.paddingExport && this.defaultPaddingExport) {
            paddingChanged = !isEqual(this.paddingExport, this.defaultPaddingExport);
            this.paddingExport = { ...this.defaultPaddingExport };

        }
        else if (!this.props.exportMode && this.padding && this.defaultPadding) {
            paddingChanged = !isEqual(this.padding, this.defaultPadding);
            this.padding = { ...this.defaultPadding };
        }
        // console.log('[resetPadding], paddingChanged', paddingChanged);
        if (paddingChanged) this.resizeComponent(getNodeWidth(this.mountNode), getNodeHeight(this.mountNode))

    }

    changeAxes = () => {
        const svg = select(this.mountNode)
        // const axesSvg = svg.select('#axes');
        const axesSvg = svg.select('#axes');

        const leftPadding = this.getPadding().left;

        axesSvg.select(`#${this.componentName}TimeAxis`)
            .attr('transform', `translate(${leftPadding}, ${this.componentName !== 'binFrequencies' ? this.getPadding().title : 0})`);

        axesSvg.select(`#${this.componentName}Plot`)
            .attr('transform', `translate(${leftPadding}, 0)`);

        axesSvg.select(`#${this.componentName}YAxis`)
            .attr('transform', `translate(${leftPadding}, 0)`);

        select(`#${this.componentName}Plot`).attr('transform', `translate(${leftPadding}, 0)`);

        this.translateGraphElements()
    }

    setYAxisTextWidth = (newValue) => {
        // console.log(this.props)
        // console.log('[yAxisTextWidth] 1', this.componentName, this.width)
        const diff = this.padding.left - newValue;
        this.padding.left = newValue;
        this.paddingExport.left = newValue
        this.width = this.width + diff
        // console.log('[yAxisTextWidth] 2', this.componentName, this.width, diff, newValue)
    }

    setWidth = (width) => {
        const padding = this.getPadding();

        this.width = width - (padding.left + padding.right);
        // console.log(`[setWidth]: ${this.componentName} => ${width} / ${this.width}, padding = ${padding.left + padding.right}`);
    }

    setHeight = (height) => {
        const padding = this.getPadding();
        this.height = height - (padding.top + padding.bottom + (padding.title || 0));
        // console.log(`[setHeight]: ${this.componentName} => ${height} / ${this.height},  padding = ${padding.top + padding.bottom + (padding.title || 0)}`);
    }

    getWidth = () => this.width;
    getHeight = () => this.height;

    setGraphAreaSize = () => {
        // console.log('setGraphAreaSize', this.componentName);
    
        const padding = this.getPadding()
        select(this.mountNode)
            .attr('width', this.width + padding.left + padding.right)
            .attr('height', this.height + padding.top + padding.bottom + (padding.title || 0));

        // console.log('SET GRAPH SIZE', this.componentName, {width: this.width, height: this.height});
        select(`#clip_${this.componentName}`)
            // .attr('transform', `translate(0,0)`)
            .attr('width', this.width + padding.right + padding.left)
            .attr('height', this.height + padding.top + padding.bottom + (padding.title || 0));
    }

    // resizeChartGraph = (width, height) => {
    //     this.setWidth(width);
    //     this.setHeight(height);
    //     this.setSize();
    //     console.log(`[resizeChartGraph]: ${this.componentName} => height = ${height} / ${this.height}`);
    // };

    // Graph specific elements
    prepareGraphElements = () => {
        const svg = select(this.mountNode).select('g.graph');
        // const xAxis =
        svg.append('g')
            .attr('id', `${this.componentName}TimeAxis`);

        // const yAxis =
        svg.append('g')
            .attr('id', `${this.componentName}YAxis`);
    }

    getComponentTransform = () => {
        return `translate(0, 0)`
    }

    getClipPath = () => `url(#clipPath_${this.componentName})`;



    prepareGraphArea = async (shortened = false) => {
        const svg = select(this.mountNode);
        // 1. Remove any previously created `<g>` with the same id (this.componentName)
        // svg.select(`#${this.componentName}`).remove();
  
        // 2. Remove old <defs> to avoid duplicating clipPath, filters, etc.
        //    (If you have other filters/definitions in the same <svg> you want to keep,
        //     you may want to remove them more selectively, e.g. only the ones with a certain ID.)
        // svg.selectAll('defs').remove();

        if (!shortened) {
            svg.append('g')
                .attr('class', 'graph')
                .attr('transform', this.getComponentTransform())
                .attr('clip-path', this.getClipPath())
                .attr('id', this.componentName);
        }
        const defs = svg.append('defs');

        defs.append('clipPath')
            .attr('id', `clipPath_${this.componentName}`)
            .append('rect')
            .attr('id', `clip_${this.componentName}`);

        defs.append('font')
            .append('font-face')
            .attr('font-family', "'IBM Plex Sans', sans-serif");

            
        const shadowFilter = defs.append('filter').attr('id', 'shadow').attr('height', '130%');
        shadowFilter.append('feGaussianBlur').attr('in', "SourceAlpha").attr('stdDeviation', "2");
        shadowFilter.append('feOffset').attr('dx', 2).attr('dy', 2).attr('result', 'offsetblur')

        // shadowFilter.append('feFlood').attr('flood-color', '#000');
        // shadowFilter.append('feComposite').attr('in2', 'offsetblur').attr('operator', 'in');
        shadowFilter.append('feComponentTransfer').append('feFuncA').attr('type', 'linear').attr('slope', '0.25'); // <!-- slope is the opacity of the shadow -->
        const feMerge = shadowFilter.append('feMerge');
        feMerge.append('feMergeNode');
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic') ///> <!-- this contains the element that the filter is applied to -->

        
        this.prepareGraphElements();
        // console.log('PREPARE GRAPH ELEMENTS', this.componentName, 'SET SIZE 1');
        this.setGraphAreaSize();
    };

    _getXValue = v => getScaledValue(isFunction(this.xScale) ? this.xScale() : this.xScale, v);

    _getYValue = v => getScaledValue(isFunction(this.yScale) ? this.yScale() : this.yScale, v);

    getXScale = () => getScale(isFunction(this.xScale) ? this.xScale() : this.xScale);

    getYScale = () => getScale(isFunction(this.yScale) ? this.yScale() : this.yScale);

    x = d => {
        throw new Error('You have to implement the method x!');
    };
    // getScaledValue('xFrequencyScale', d.time);

    y = v => {
        throw new Error('You have to implement the method y!');
    };
    // getScaledValue('yFrequencyScale', v);

    renderD3Component = () => {
        throw new Error('You have to implement the method renderD3Component!');
    }

    reinitXYScales = () => {
        throw new Error('You have to implement the method reinitXYScales!');
    }

    translateGraphElements = () => {
        throw new Error('You have to implement the method translateGraphElements!');
    }

    resizeComponent = (width, height) => {
        // console.log('resizeComponent', this.componentName, { width, height});
        this.setWidth(width);
        this.setHeight(height);
        this.reinitXYScales();
        this.setGraphAreaSize();
        this.translateGraphElements();
    };
}

export default D3Component;
