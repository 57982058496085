import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, Box, Chip, FormControl } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash.debounce';
import { makeStyles } from '@mui/styles';
import { usePrevious } from '../../functions/customHooks';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import { setAutompleteOptions } from '../../redux/actions/autocompleteActions';
import { bindActionCreators } from 'redux';
import { printWhatChanged } from '../../functions/utils';

export const styles = (theme) => ({
    autocomplete: {
        marginBottom: '10px',
    },
    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
    textField: {
        margin: '0px',
        fontSize: '14px',
    },
    error: {
        color: 'red',
        fontSize: '12px',
        marginTop: '3px',
    },
    chipsContainer: {
        maxHeight: 284,
        overflowY: 'overlay',
        overflowX: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        width: '98%',
        paddingRight: theme.spacing(1),
        '&::-webkit-scrollbar': {
            display: 'block',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px #cccccc',
        },
        '&::-webkit-scrollbar-thumb': {
            display: 'block',
        },
    },
});

const useStyles = makeStyles(styles);

const defaultErrorRenderer = (errorMsg) => (
    <span>
        {errorMsg.split('\n').map((msg, index) => (
            <div key={index}>{msg}</div>
        ))}
    </span>
);

const logId = 'antigenic';

const AutocompleteInput = _props => {
    const {
        valueIds,
        fetchOptions,
        onSelect,
        getOptionLabel,
        label,
        errorTxt: propsErrorTxt = '',
        onMouseEnter,
        onMouseLeave,
        isOptionEqualToValue,
        multiple = false,
        debounceTime = 300,
        minTextLength = 3,
        showMinTextLength = true,
        initialOptions = [],
        shouldFetchOptions = true,
        fetchOptionLabel,
        helperText: propsHelperText = null,
        autocompleteOptions,
        setAutompleteOptions,
        formatId = (id) => id,
        ...props
    } = _props;

    // console.log('AutocompleteInput', {id: props.id, valueIds});
    // const prevProps = usePrevious(_props);


    const classes = useStyles();
    // const [inputValue, setInputValue] = useState('');
    // const [options, setOptions] = useState(initialOptions);

    const autocompleteId = props.id;

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorTxt, setErrorTxt] = useState(propsErrorTxt);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(multiple ? [] : null);

    // const state = { inputValue, selectedOption, loading, errorTxt, isOpen };
    // const prevState = usePrevious( {inputValue, selectedOption, loading, errorTxt, isOpen });

    // if (autocompleteId === logId) printWhatChanged(_props, state, prevProps, prevState, 'AutocompleteInput');
    
    // const prevMultiple = usePrevious(multiple);

    const requestIdRef = useRef(0);


    const options = useMemo(() => autocompleteOptions?.length && shouldFetchOptions
        ? autocompleteOptions 
        : initialOptions, 
    [autocompleteOptions, initialOptions, shouldFetchOptions]);

    const optionsDict = useMemo(() => options.reduce((acc, option) => {
        acc[option.id] = option;
        return acc;
    }, {}), [options]);

 
    const debouncedFetchOptions = useMemo(
        () => {
            // if (autocompleteId === logId) console.log('redeclare debouncedFetchOptions', autocompleteId, debounceTime);
            return debounce(async (txtValue, currentFetchId) => {
                setLoading(true);
                setErrorTxt('');
                try {
                    const fetchedOptions = await fetchOptions(txtValue);
                    // Only update options if this is the latest request
                    if (currentFetchId === requestIdRef.current) {
            
                        // if (autocompleteId === logId) 
                        //     console.log('1. [AutocompleteInput.debouncedFetchOptions] setAutompleteOptions', { autocompleteId, fetchedOptions, txtValue}, _props);
                        // setOptions(fetchedOptions);
                        setAutompleteOptions({autocompleteId, options: fetchedOptions, inputValue});
                    }
                } catch (err) {
                    if (currentFetchId === requestIdRef.current) {
                        setErrorTxt(err.message || 'Error fetching options');
                        // setOptions(initialOptions);
                        if (autocompleteId === 'searchStrain') console.log('2. [AutocompleteInput.debouncedFetchOptions] setAutompleteOptions', { autocompleteId, initialOptions});
                        setAutompleteOptions({autocompleteId, options: initialOptions});
                    }
                } finally {
                    if (currentFetchId === requestIdRef.current) {
                        setLoading(false);
                    }
                }   
            }
            , debounceTime);
        },
        [fetchOptions, debounceTime]
    );

    // useMemo(() => {
    //     console.log('INIT useMemo', props.id, {inputPattern});
    // }, [])
    // useEffect(() => {
    //     console.log('INIT useEffect', props.id, {inputPattern});
    // }, [])

    useEffect(() => {
        return () => {
            // Cancel any ongoing requests if the component unmounts
            debouncedFetchOptions.cancel();
        };
    }, [debouncedFetchOptions]);

    useEffect(() => {
        // Sync errorTxt with propsErrorTxt if needed
        setErrorTxt(propsErrorTxt);
    }, [propsErrorTxt]);

    const isInitialMount = useRef(true);
    useEffect(() => {
        // if (autocompleteId === logId) console.log('useEffect', { shouldFetchOptions, inputValue, valueIds, options, isInitialMount: isInitialMount.current });
        if (!shouldFetchOptions || isInitialMount.current) {
            isInitialMount.current = false;
            // if (autocompleteId === logId) console.log('useEffect return INITIAL MOUNT', autocompleteId);
            return;
        };
        if (inputValue === '' && !isNil(valueIds)) return;
        // if (autocompleteId === logId) console.log('useEffect', { autocompleteId, inputValue, valueIds, options });
        const currentFetchId = ++requestIdRef.current;

        // If less than 3 chars, no need to fetch, cancel debounced call and reset state
        if (!inputValue || inputValue.length < minTextLength) {
            debouncedFetchOptions?.cancel();
            //console.log('2. [AutocompleteInput] setOptions (initial)', { id: props.id, inputValue, initialOptions})
            // setOptions(initialOptions);
            // if (autocompleteId === logId) console.log('3. [AutocompleteInput] setAutompleteOptions', { autocompleteId, initialOptions, inputValue, options});
            setAutompleteOptions({autocompleteId, options: initialOptions});
            setLoading(false);
            setErrorTxt('');
            return;
        }

        // Fetch only when input length == 3 or when no options are loaded yet
        if (inputValue.length === minTextLength || options.length === 0) {
            debouncedFetchOptions(inputValue, currentFetchId);
        }
        // If inputValue > minTextLength and options are already present,
        // rely on Autocomplete's built-in filtering and do not fetch again
    }, [inputValue, valueIds, debouncedFetchOptions /*, options.length*/]);

  

    useEffect(() => {
        const getOption = async () => {
            // Return early with empty state if no valueIds
            if (isNil(valueIds) || !`${valueIds}`.length) {
                setSelectedOption(multiple ? [] : null);
                return;
            }

            try {
                if (multiple) {
                    const ids = `${valueIds}`.split(',').filter(id => id).map(id => formatId(id)); // Filter out empty strings
                    //console.log('ids', ids)
                    const promises = ids
                        .filter(id => id && (optionsDict[id] || fetchOptionLabel))
                        .map(async id => ({
                            id, // Ensure id is in correct format
                            n: optionsDict[id] ? getOptionLabel(optionsDict[id]) : await fetchOptionLabel?.(id)
                        }));
                    const selectedOptions = 
                        (await Promise.all(promises)).map(option => ({ ...option, error: !optionsDict[option.id] }));
                        // .filter(option => options.find(o => o.id === option.id)); //filter out options that are not in the options list
                    setSelectedOption(selectedOptions || []); // Ensure array if resolution fails
                    setInputValue('');
                    return;
                }

                // Single value case
                if (!valueIds) {
                    setSelectedOption(multiple ? [] : null);
                    return;
                }

                const option = {
                    id: formatId(valueIds),
                    n: optionsDict[valueIds] ?
                        getOptionLabel(optionsDict[valueIds]) :
                        await fetchOptionLabel?.(valueIds) || ''
                };
               
                setSelectedOption(multiple ? [option] : option);
                setInputValue(option.n);
            } catch (error) {
                console.error('Error processing options:', error);
                setSelectedOption(multiple ? [] : null);
            }
        };
        getOption();
    }, [valueIds, multiple, options /*optionsDict, getOptionLabel, fetchOptionLabel*/]);

    const handleInputChange = useCallback((event, newInputValue) => {
        // if (autocompleteId === logId) console.log('handleInputChange', { eventType: event?.type, event, newInputValue, inputValue});
        if (!event?.type || ['blur'/*, 'click'*/].includes(event?.type)) {
            // console.log('handleInputChange RETURN');
            return;
        }
        setInputValue(newInputValue);
   
        // If the input was cleared, reset states immediately
        if (newInputValue === '') {
            debouncedFetchOptions.cancel();
            // console.log('3. [AutocompleteInput] setOptions (initial)', { id: props.id, inputValue, initialOptions})
            // setOptions(initialOptions);
            // if (autocompleteId === logId) console.log('4. [AutocompleteInput] setAutompleteOptions', { autocompleteId, initialOptions});
            setAutompleteOptions({autocompleteId, options:  initialOptions});
            setLoading(false);
            setErrorTxt('');
        }
    }, [debouncedFetchOptions]);


    const handleChange = useCallback(
        (_event, newValue) => {
            const valueIds = multiple ? (newValue || []).map((opt) => opt.id) : newValue ? newValue.id : null;
            // console.log('[handleChange]', {autocompleteId, valueIds, newValue});
            // if (autocompleteId === logId) console.log('handleChange', { autocompleteId, valueIds});
            onSelect(valueIds);
        },
        [onSelect, multiple]
    );

    const handleMouseEnterInternal = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (multiple && Array.isArray(selectedOption) && selectedOption.length > 0) {
            onMouseEnter?.(selectedOption.map((v) => v.id));
        } else if (!multiple && selectedOption) {
            onMouseEnter?.(selectedOption.id);
        }
    };

    const handleMouseLeaveInternal = (event) => {
        event.preventDefault(); 
        event.stopPropagation();
        // Only trigger onMouseLeave if the dropdown is closed
        if (!isOpen) {
            onMouseLeave?.();
        }
    };


    const renderOptionInternal = useCallback(
        (props, option) => {
            return (
                <li
                    {...props}
                    key={option.id}
                    style={{
                        fontFamily: option.vaccine ? 'Inter Bold' : 'Inter',
                    }}
                >
                    {getOptionLabel(option)}
                </li>
            );
        },
        [getOptionLabel]
    );

    const showError = (errorTxt || propsErrorTxt) && (errorTxt?.length > 0 || propsErrorTxt?.length > 0);

    const helperText = propsHelperText || (showMinTextLength ? (inputValue.length < minTextLength && !loading ? 'Type at least 3 characters' : '') : '');

    
    // console.log('AutocompleteInput RENDER', props.id );
    if ((multiple && !Array.isArray(selectedOption)) || (!multiple && Array.isArray(selectedOption))) return null;
    return (
        <FormControl fullWidth className={classes.formControl}>
            <Autocomplete
                {...props}
                id={props.id}
                multiple={multiple}
                value={selectedOption}
                inputValue={inputValue}
                options={options?.length ? options : initialOptions}
                getOptionLabel={getOptionLabel}
                onInputChange={handleInputChange}
                onChange={handleChange}
                loading={loading}
                autoSelect={true}
                renderOption={renderOptionInternal}
                onMouseEnter={handleMouseEnterInternal}
                onMouseLeave={handleMouseLeaveInternal}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                className={classes.autocomplete}
                isOptionEqualToValue={isOptionEqualToValue}
                fullWidth
                renderTags={() => null}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        id={props.id}
                        label={label}
                        variant="standard"
                        helperText={helperText}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                        className={classes.textField}
                        multiline
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                id: props.id,
                                endAdornment: (
                                    <>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </>
                                )
                            },  
                        }}
                    />
                )}
            />
            {Array.isArray(selectedOption) && selectedOption.length > 0 && (
                <Box className={classes.chipsContainer}>
                    {selectedOption.map((option, index) => (
                        <Chip 
                            key={option.id}
                            label={option.n}
                            onDelete={() => handleChange(null, selectedOption.filter(o => o.id !== option.id))}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '5px',
                                height: 'auto',
                                padding: '8px 4px',
                                '& .MuiChip-label': {
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                    flexGrow: 1,
                                    height: 'auto',
                                },
                                '& .MuiChip-deleteIcon': {
                                    marginLeft: 'auto'
                                },
                                '&:hover': {
                                    cursor: 'pointer',
                                    backgroundColor: 'rgba(0, 0, 0, 0.08) !important', // Use !important to ensure no background color change on hover
                                }
                            }}
                        />
                    ))}
                </Box>
            )}
            {showError && (
                <div className={classes.error}>
                    {defaultErrorRenderer(errorTxt || propsErrorTxt)}
                </div>
            )}
        </FormControl>
    );
};

AutocompleteInput.propTypes = {
    fetchOptions: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    errorTxt: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    isOptionEqualToValue: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    debounceTime: PropTypes.number,
};

const mapStateToProps = ({ autocomplete }, ownProps) => ({
    autocompleteOptions: autocomplete?.[ownProps.id]
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setAutompleteOptions,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteInput);
