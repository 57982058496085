// External imports
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
// Redux imports
import { setParameters } from '../../../redux/actions/parametersActions';
import { setHiddenMenu } from '../../../redux/actions/uiActions';
import { getIsMobile } from '../../../redux/selectors/statusSelector';

// Component imports

import Alerts from '../../Alerts/Alerts';
import BuildSelector from '../../OptionsSelector/LineageSelector/BuildSelector';

// Icons and styles
import HamburgerIcon from '../../SvgIcons/HamburgerIcon';
import { HideIcon } from '../../Alerts/styles';
import { styles } from './styles';
import DatePickerInput from '../../Common/DatePickerInput';
import SelectInput from '../../Common/SelectInput';
import { vaccinesPredictedProtectionDateSelector, vaccinesTrackedProtectionDateSelector } from '../../../redux/selectors/parametersSelector';
import StrainsSubsetSelector from '../../OptionsSelector/StrainsSubsetSelector';
import ReferenceStrainsSelector from './ReferenceStrainsSelector';
import CladesSelector from './CladesSelector';
import VaccinesModelSelector from '../../ModelSelector/VaccinesModelSelector';

// Selectors that should be shown during intro
// const introSelectors = {
//     colorBySelector: true,
//     modelSelector: true,
//     antigenicColorByOptionsSelector: true,
//     layoutSelector: true,
//     treeScaleTypeSelector: true,
//     strainCutoffDateSelector: true,
//     nodeVisibilitySelector: true,
//     searchStrainSelector: true
// };

const useStyles = makeStyles(styles);

const serumTypes = [
    'CELL', 
    'EGG'
];
/**
 * TreeGraphOptions component - Controls and options for the phylogenetic tree visualization
 */
const VaccinesOptions = props => {
    const { 
     
        nodeId, 
        vaccinesTrackedProtectionDate,
        vaccinesPredictedProtectionDate,
        vaccinesSerumType,
        permissions, 
        showAlert, 
        hiddenMenu, 
        isMobile, 
        intro, 
        setHiddenMenu,
        setParameters
    } = props;

    const classes = useStyles();

    // Determine if a selector should be shown based on permissions and intro state
    //const showSelector = (selectorName) => permissions[selectorName] || (intro && introSelectors[selectorName]);

    // Handle menu visibility toggle
    const handleHideClick = () => {
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    };

    const handleChange = (name) =>(value) => {
        setParameters({ [name]: value });
    };

    console.log('[VaccinesOptions] hiddenMenu', { hiddenMenu });
    return (
        <>
            {!hiddenMenu ?
                <div
                    className={showAlert ?
                        isMobile ?
                            classes.rootWithAlertsMobile
                            : classes.rootWithAlerts
                        : isMobile ?
                            classes.rootWithoutAlertsMobile
                            : classes.rootWithoutAlerts
                    }>
                    <div style={{ width: '100%' }}>
                        <form autoComplete="off" style={{ paddingBottom: '55px' }}>
                            {!isMobile && !nodeId &&
                                    <div className={classes.buttonWrapper}>
                                        <IconButton className={classes.hideButton} onClick={handleHideClick}>
                                            <HideIcon />
                                        </IconButton>
                                    </div>
                            }
                            <Alerts module="strainTree" />
                            <BuildSelector />
                            <Grid container columnSpacing={2}>
                                <Grid size={6}>
                                    <DatePickerInput
                                        label='Tracked protection date'
                                        value={vaccinesTrackedProtectionDate}
                                        onChange={handleChange('vaccinesTrackedProtectionDate')}
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <DatePickerInput
                                        label='Predicted protection date'
                                        value={vaccinesPredictedProtectionDate}
                                        onChange={handleChange('vaccinesPredictedProtectionDate')}
                                    />
                                </Grid>
                            </Grid>
                            <SelectInput
                                label='Serum type'
                                value={vaccinesSerumType}
                                onChange={handleChange('vaccinesSerumType')}
                                options={serumTypes}
                            />
                            <VaccinesModelSelector />
                            <StrainsSubsetSelector context="vaccines" />
                            <CladesSelector />
                            <ReferenceStrainsSelector />

                            
                        </form>
                    </div>
                </div>
                :
                <IconButton className={classes.expandButton} onClick={handleHideClick}>
                    <HamburgerIcon />
                </IconButton>
            }
        </>
    );
};

VaccinesOptions.propTypes = {
    colorBy: PropTypes.string,
    modelParam: PropTypes.bool,
    nodeId: PropTypes.number,
    permissions: PropTypes.object.isRequired,
    showAlert: PropTypes.bool.isRequired,
    hiddenMenu: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    showCladeBar: PropTypes.bool,
    intro: PropTypes.bool,
    setParameters: PropTypes.func.isRequired,
    setHiddenMenu: PropTypes.func.isRequired,
};

/**
 * Maps Redux state to component props
 */
const mapStateToProps = (state) => {
    const { user, alert, ui, parameters } = state;
    const { vaccinesSerumType } = parameters;
    //“Date for tracked protection” and “Date for predicted protection”}
    const isMobile = getIsMobile();
    // console.log({ vaccinesTrackedProtectionDate, vaccinesPredictedProtectionDate, vaccinesSerumType });
    return {
        vaccinesTrackedProtectionDate: vaccinesTrackedProtectionDateSelector(state), 
        vaccinesPredictedProtectionDate: vaccinesPredictedProtectionDateSelector(state),
        vaccinesSerumType: vaccinesSerumType || null,
        permissions: user.permissions.vaccinesPermissions,
        showAlert: alert.strainTree.show,
        hiddenMenu: isMobile ? ui.hiddenMenuMobile : ui.hiddenMenu,
        isMobile
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        setHiddenMenu
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesOptions);
