import { max } from 'd3-array';
import { isPlainObject } from 'lodash';
import appConfig from '../config/appConfig';
import { PATHOGEN_TYPES } from '../config/dictionaries';

const isNull = (value) => value === null || value === undefined;

const emptyObject = (obj) => isNull(obj) || (Object.keys(obj).length === 0 && obj.constructor === Object);

export const emptyObjectOrArray = (value) => {
    if (Array.isArray(value)) {
        return value.length === 0;
    } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
    }
    return false;
};

const isEmpty = (value) => typeof value === 'undefined' || value === null || value === false;

const isNumeric = (value) => !isEmpty(value) && !Number.isNaN(Number(value));

const runAction = (callback, ...params) => {
    setTimeout(callback(...params), 0);
};

const propToString = (val) => {
    if (val instanceof Object) return `Object, keys: ${Object.keys(val).length}`;
    return val;
};

const treePostOrder = (rootNode, callback) => {
    // log(`[treePostOrder]: ${rootNode.id}`);
    let node = rootNode;
    let parent = null;
    const nodes = [{ node, parent }];
    // log(`[treePostOrder]: ${JSON.stringify(nodes)}`);
    const next = [];
    let children;
    let i;
    let n;
    // log(nodes);
    let val;
    while ((val = nodes.pop())) {
        // log(`1. [treePostOrder] val = ${val}`);
        node = val.node;
        parent = val.parent;
        // log(`2. [treePostOrder] ${node.id}, ${parent ? parent.id : ''}, ${nodes.length}`);
        next.push({ node, parent });
        children = node.children;
        if (children) {
            for (i = 0, n = children.length; i < n; ++i) {
                nodes.push({ node: children[i], parent: node });
            }
        }
    }
    while ((val = next.pop())) {
        // log(`3. [treePostOrder] val = ${val}`);
        node = val.node;
        parent = val.parent;
        callback(node, parent);
    }
    return rootNode;
};

const treePreOrder = (rootNode, callback) => {
    let node = rootNode;
    let parent = null;
    const nodes = [{ node, parent }];
    // const next = [];
    let children;
    let i;
    let val;
    while ((val = nodes.pop())) {
        node = val.node;
        parent = val.parent;
        callback(node, parent);
        // next.push(node);
        children = node.children;
        if (children) {
            for (i = 0; i < children.length; ++i) {
                nodes.push({ node: children[i], parent: node });
            }
        }
    }
    return rootNode;
};

const minNumber = (n1, n2) => {
    if (isNull(n1) && isNull(n2)) return null;
    if (isNull(n1)) return n2;
    if (isNull(n2)) return n1;
    return Math.min(n1, n2);
};

const maxNumber = (n1, n2) => {
    if (isNull(n1) && isNull(n2)) return null;
    if (isNull(n1)) return n2;
    if (isNull(n2)) return n1;
    return Math.max(n1, n2);
};

const titleCase = (str) => (str || '').toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());

// const daysToDate = (d) => new Date(1900, 0, d, 0, 0, 0, 0);


const daysToDate = (days) => {
    const startDate = new Date(Date.UTC(1900, 0, 1)); // Starting from January 1, 1900
    const resultDate = new Date(startDate.getTime() + (days - 1) * (1000 * 3600 * 24)); // Subtracting 2 because the startDate is already day 1 and JavaScript dates are 0-indexed for days
    return resultDate;
};

const dateToDays = (d) => {
    if (emptyObject(d) || Number.isNaN(d)) return null;
    d.setHours(0, 0, 0, 0);
    const startDate = new Date(Date.UTC(1900, 0, 1, 0, 0, 0, 0)); // Starting from January 1, 1900
    const timeDiff = d - startDate;
    const daysDiff = timeDiff / (1000 * 3600 * 24);
    return Math.round(daysDiff + 1);
};

export const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date);
};

// const dateToDays = (d) => {
// if (emptyObject(d) || Number.isNaN(d)) return null;
// d.setHours(0, 0, 0, 0);
// const date0 = new Date(1900, 0, 0, 0, 0, 0, 0);
// const timeDiff = Math.abs(date0.getTime() - d.getTime());
// const diffDays = Math.round(timeDiff / (1000 * 3600 * 24));
// return diffDays;

// };

const yearFirstDate = (d) => new Date(daysToDate(d).getFullYear(), 0, 1);

const quarterFirstDate = (d) => {
    const date = daysToDate(d);
    const m = date.getMonth();
    const mF = Math.floor(m / 3) * 3;
    // console.log(`quarterFirstDate = ${new Date(date.getFullYear(), mF, 1)}, year = ${date.getFullYear()}, month = ${mF}`);
    return new Date(date.getFullYear(), mF, 1);
};

const yearLastDate = (d) => new Date(daysToDate(d).getFullYear() + 1, 0, 0);

const quarterLastDate = (d) => {
    const date = daysToDate(d);
    const m = date.getMonth();
    const mF = (Math.floor(m / 3) + 1) * 3;
    return new Date(date.getFullYear(), mF, 0);
};

const monthFirstDate = (d) => {
    const date = asDate(d); // instanceof Date ? d : daysToDate(d);
    const m = date.getMonth();
    return new Date(date.getFullYear(), m, 1);
};

const monthLastDate = (d) => {
    // const date = daysToDate(d);
    const date = d instanceof Date ? d : daysToDate(d);
    const m = date.getMonth();
    return new Date(date.getFullYear(), m + 1, 0);
};

const isDateString = d => typeof d === 'string' && Date.parse(d);

const isNumberString = d => typeof d === 'number' || (typeof d === 'string' && /^-?\d+$/.test(d.trim()));


const asDate = d => {
    if (d instanceof Date) return d;
    if (isNumberString(d)) return daysToDate(d);
    if (isDateString(d)) return getDateFromString(d);
    return d;
};

const getDefaultTrackingFromDate = (defaultPredictionBaseline, frequencyChartYearSpan, alignment) => {
    console.log(defaultPredictionBaseline);
    const trackingFromDate = new Date(defaultPredictionBaseline.getTime());
    trackingFromDate.setFullYear(trackingFromDate.getFullYear() - frequencyChartYearSpan);
    const td = dateToDays(trackingFromDate);
    if (alignment === 'Y') return yearFirstDate(td);
    if (alignment === 'Q') return quarterFirstDate(td);
    return monthFirstDate(td);
};

const getDefaultTrackingToDate = (defaultPredictionBaseline, frequencyChartYearSpan, alignment) => {
    const trackingFromDate = new Date(defaultPredictionBaseline.getTime());
    trackingFromDate.setFullYear(trackingFromDate.getFullYear() + frequencyChartYearSpan);
    const td = dateToDays(trackingFromDate);
    if (alignment === 'Y') return yearLastDate(td);
    if (alignment === 'Q') return quarterLastDate(td);
    return monthLastDate(td);
};

const getAcademicYear = (t) => {
    const d = daysToDate(t);
    const firstYearDay = new Date(d.getFullYear(), 9, 1);
    const year = d.getTime() < firstYearDay.getTime() ? d.getFullYear() : d.getFullYear() + 1;
    return year;
};

const logSum = (valArr, getter) => {
    // console.log(valArr);
    const values = getter ? valArr.map((v) => getter(v)) : valArr;

    const ma = max(values);
    // log(`ma == ${ma}`);
    if (values.length === 0 || ma === Number.NEGATIVE_INFINITY) return Number.NEGATIVE_INFINITY;

    const sumVal = values.reduce((tmpSum, x) => tmpSum + Math.exp(x - ma), 0);
    const ret = Math.log(sumVal) + ma;
    return ret;
};

const isFunction = (arg) => typeof arg === 'function';

const pick =
    (...props) =>
        (o) =>
            props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});

export const prepareParamsUrl = (params = {}, exportMode = false) => {
    const dictParams = {}; // branchNodes: true };
    const allowNullParams = { strainCutOffDate: true };

    const disallowedParam = (p) => isNull(params[p]) && !allowNullParams[p];
    const objectParams = { vaccinesModel: true };
    const disallowedObjectParam = (p) => //!objectParams[p] &&
        typeof params[p] === 'object' &&
        !isNull(params[p]) &&
        !(params[p] instanceof Date || params[p] instanceof Array || objectParams[p] || dictParams[p]);

    

    const isObjectParam = (p) => typeof params[p] === 'object' && !isNull(params[p]) && !(params[p] instanceof Date);
    const paramsUrl = Object.keys(params)
        .sort()
        .reduce((tmpUrl, p) => {
            // console.log(`params: ${p} = ${params[p]}, disallowedParam = ${disallowedParam(p)}, disallowedObjectParam = ${disallowedObjectParam(p)}`);
            // console.log(`p = ${params[p]}, ${typeof params[p]}, array = ${params[p] instanceof Array}`);

            if (disallowedParam(p) || disallowedObjectParam(p))
            //(typeof params[p] === 'object' && !(params[p] instanceof Date) && !(params[p] instanceof Array) && !dictParams[p])

                return tmpUrl;
            let val = params[p];
            if (dictParams[p] && typeof params[p] === 'object')
                val = Object.keys(val)
                    .filter((k) => val[k])
                    .join(',');
            else if (params[p] instanceof Date) val = dateToDays(params[p]);
            else if (dateParams[p] && typeof params[p] === 'string' && !exportMode) val = dateToDays(new Date(params[p]));
            else if (params[p] instanceof Array || isObjectParam(p)) val = JSON.stringify(params[p]); //params[p].join(',');


            if (p === 'branchNodes' && params[p] instanceof Array) {
                // console.log(`${JSON.stringify(params[p])}, ${typeof params[p]}`);
                val = params[p] && params[p] instanceof Array ? params[p].join(',') : '';
            }
            const pTxt = `${tmpUrl.length > 0 ? '&' : '?'}${p}=${val}`;
            return `${tmpUrl}${pTxt}`;
        }, '');
    return paramsUrl;
};

const getPathogenType = (pathogen) => appConfig.pathogenTypes[pathogen]?.value || PATHOGEN_TYPES.INFLUENZA.value;

const prepareUrl = (urlTxt, params = {}, exportMode = false) => {
    const paramsUrl = prepareParamsUrl(params, exportMode);
    return `${urlTxt}${paramsUrl}`;
};



const getParametersObject = (parameters) => {
    const _parameters = Object.keys(parameters).reduce((tmpParameters, p) => {
        if (parameters[p] !== undefined) tmpParameters[p] = parameters[p];
        return tmpParameters;
    }, {});
    return { parameters: _parameters };
};

const transformParamsToUrl = {
    editMode: p => JSON.stringify(p),
    hiddenAlphaClades: (p) => Object.keys(p).toString(),
    hiddenRhoClades: (p) => Object.keys(p).toString(),
    branchNodes: (p) => (p && p.length > 0 ? p.join(',') : ''), //(p ? Object.keys(p).toString() : ''), 
    visibleBins: p => JSON.stringify(p), //Object.keys(p).filter(key => p[key]).toString(),
    visibleMutationClassesLabels: p => JSON.stringify(p),
    visibleMutationClasses: p => JSON.stringify(p),
    calculatedDomain: p => JSON.stringify(p),
    mapParams: p => JSON.stringify(p),
    colorScales: p => JSON.stringify(p),
    selectedModels: p => JSON.stringify(p),
    vaccinesModel: p => JSON.stringify(p),
};


const transformUrlToParams = {
    hiddenAlphaClades: (p) =>
        p.split(',').reduce((tmp, s) => {
            tmp[s] = true;
            return tmp;
        }, {}),
    hiddenRhoClades: (p) =>
        p.split(',').reduce((tmp, s) => {
            tmp[s] = true;
            return tmp;
        }, {}),
    branchNodes: (p) =>
        p.split(',').reduce((tmp, s) => {
            tmp.push(s);// tmp[s] = true;
            return tmp;
        }, []),
    visibleBins: p => JSON.parse(p),
    // (p) =>
    //     p.split(',').reduce((tmp, s) => {
    //         tmp[s] = true;
    //         return tmp;
    //     }, {}),
    editMode: p => JSON.parse(p),
    exportLegend: (p) => p === 'true',
    showLeaves: (p) => p === 'true',
    searchStrainMode: (p) => p === 'true',
    showInternalNodes: (p) => p === 'true',
    showColoredNodesOnly: (p) => p === 'true',
    showCladeLabels: (p) => p === 'true',
    showMutations: (p) => p === 'true',
    showMutationsGroups: (p) => p === 'true',
    showReferenceStrains: (p) => p === 'true',
    settings: p => p === 'true',
    antigenicTiterType: (p) => `${p}`,
    calculatedDomain: (p) => JSON.parse(p),
    colorScales: p => JSON.parse(p),
    mapParams: p => JSON.parse(p),
    visibleMutationClassesLabels: p => JSON.parse(p),
    visibleMutationClasses: p => JSON.parse(p),
    selectedModels: p => JSON.parse(p),
    vaccinesModel: p => JSON.parse(p)
};

export const dateParams = {
    trackingFrom: true,
    trackingTo: true,
    predictionBaseline: true,
    freqsFrom: true,
    freqsTo: true,
    strainCutOffDate: true,
    date: true, // last updated date,
    submissionDate: true,
    vaccinesTrackedProtectionDate: true,
    vaccinesPredictedProtectionDate: true,
};

const prepareParameters = (parameters) => {

    const numberParams = {
        zoomNodeId: true,
        refClade: true,
        binCnt: true,
        width: true,
        height: true,
        sigmaAg: true,
        tau: true,
        seqSpan: true,
        freqSpan: true,
        deltaT: true,
        caseSpan: true,
        stdDays: true,
        seqStdDays: true,
        caseStdDays: true,
        showLeafNumber: true,
        cladeFrequencyThreshold: true,
        cladeActiveDays: true,
        mutationsThreshold: true,
        mutposition: true,
        minLogSpace: true,
        version: true,
    };
    const booleanParams = {
        exportMode: true,
        showCladeLabels: true,
        showLeaves: true,
        showInternalNodes: true,
        showColoredNodesOnly: true,
        showMutations: true,
        showMutationsGroups: true,
        showReferenceStrains: true,
        displayErrorBars: true,
        showPrediction: true,
        showVaccines: true,
        showReassortments: true,
        largePDF: true,
        logSpace: true,
        showCladeBar: true,
        showCladeBarLabels: true,
        displayGreyZone: true,
        intro: true,
        showAntigenicTableValues: true,
        useRegionalCorrections: true,
        wrapAntigenicTableHeaders: true,
        showLinks: true
    };
    
    const arrayParams = { vaccinesFerretRefStrains: true, vaccinesRhos: true };
    const commaSeparatedParams = { branchNodes: true };
    const params = emptyObject(parameters)
        ? {}
        : Object.keys(parameters).reduce((tmpParams, p) => {
            let val = parameters[p];
            
            if (p in dateParams && !emptyObject(parameters[p])) {
                
                if (dateParams[p] === 'MONTH_FIRST_DAY') val = monthFirstDate(val);
                else if (dateParams[p] === 'MONTH_LAST_DAY') val = monthLastDate(val);
                else val = asDate(val);

                if (val !== 'null') val = val.toISOString();
                else val = null;
            }
            else if (p in numberParams && (typeof val === 'string' || val instanceof String)) {
                if (val === '') val = null;
                else val = parseFloat(val);// 10);
            }
            else if (p in booleanParams && (typeof val === 'string' || val instanceof String)) val = val === 'true';
            else if (p in arrayParams && (typeof val === 'string' || val instanceof String)) val = JSON.parse(val);
            else if (p in commaSeparatedParams && (typeof val === 'string' || val instanceof String))
                val = val.split(',').reduce((acc, k) => {
                    acc[k] = true;
                    return acc;
                }, {});
            
            tmpParams[p] = val;
            return tmpParams;
        }, {});

    return params;
};

const prepareSelectedModels = (selectedModels, parameters) => {
    if (parameters.modelRegionId)
        selectedModels[0].modelRegionId = parameters.modelRegionId;
    if (parameters.modelType)
        selectedModels[0].modelType = parameters.modelType;
    if (parameters.modelId)
        selectedModels[0].modelId = parameters.modelId;
    if (parameters.sigmaAg)
        selectedModels[0].sigmaAg = parameters.sigmaAg;
    if (parameters.tau)
        selectedModels[0].tau = parameters.modelId;

    return selectedModels;
};


const prepareParametersToExportUrl = (parameters) =>
    Object.keys(parameters).reduce((tmpParameters, p) => {
        tmpParameters[p] = transformParamsToUrl[p]
            ? transformParamsToUrl[p](parameters[p])
            : parameters[p];
        return tmpParameters;
    }, {});



const shouldFetch = (status) => {
    return !status || status === 'none' || status === 'refetchNeeded';
};

const isLoadedOrNA = (status) => {
    return !status || status === 'loaded' || status === 'NA'; // || status === 'uninitialized');
};

const getTextMetrics = (text, font) => {
    const canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);

    const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    //let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    //console.log(metrics.width, fontHeight, actualHeight);
    return { width: metrics.width, height: fontHeight };
};

const getTrimmedText = (text, font, maxWidth) => {
    const canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;

    let metrics = context.measureText(text);
    let lineWidth = metrics.width;

    if (lineWidth <= maxWidth) {
        return { text: text, width: lineWidth };
    }

    let truncatedText = text;

    while (lineWidth > maxWidth && truncatedText.length > 0) {
        truncatedText = truncatedText.slice(0, -1);
        metrics = context.measureText(truncatedText + '...');
        lineWidth = metrics.width;
    }

    return truncatedText + '...';
};

const checkTreeOrder = (tree, treeFreqs, treeAttrs, treeOrderDict) => {
    let treeOrderOk = true;
    const maxTimeDict = {};
    treePostOrder(tree, (node) => {
        if (!node.children) maxTimeDict[node.id] = treeAttrs[node.id].time;
        else {
            maxTimeDict[node.id] = Math.max(...node.children.map((c) => maxTimeDict[c.id]));
        }
    });

    treePostOrder(tree, (node) => {
        if (!node.children) return true;
        const childrenIds = node.children
            .map((c) => c.id)
            .sort(
                (c1, c2) => (treeFreqs[c1] || 0) - (treeFreqs[c2] || 0) || maxTimeDict[c1] - maxTimeDict[c2] || c1 - c2,
            );
        const childrenOrders = childrenIds.map((c) => treeOrderDict[c]).filter((c) => c !== null && c !== undefined);

        let prevOrder = null;
        let branchOk = true;

        childrenOrders.forEach((c) => {
            branchOk = branchOk && (prevOrder === null || c > prevOrder);
            prevOrder = c;
        });
        if (!branchOk) {
            console.log(childrenOrders);
            console.log(childrenIds.map((c) => ({ freq: treeFreqs[c], maxTime: maxTimeDict[c] })));
            console.log(`${node.id} => ${childrenOrders}, branchOk = ${branchOk}`); // / ${Math.sum(...childrenIds.map(c => treeFreqs[c] || 0))}`);
        }
        treeOrderOk = treeOrderOk && branchOk;
    });
    return treeOrderOk;
};

const compare = (a, b, prop) => {
    const _a = prop ? a[prop] : a;
    const _b = prop ? b[prop] : b;
    return _a < _b
        ? -1
        : _a > _b ? 1 : 0;
};

const arrayFromObjProps = (obj, props) => {
    return props.map(prop => obj[prop]);
};

const stratify = (data) => {
    const hashTable = data.reduce((acc, aData) => {
        // eslint-disable-next-line no-unused-vars
        const { p, ...nodeData } = aData;
        acc[aData.id] = nodeData;
        return acc;
    }, {});

    const dataTree = [];
    data.map((aData) => {
        if (!aData.p) {
            dataTree.push(hashTable[aData.id]);
        } else {
            if (!hashTable[aData.p].children) hashTable[aData.p].children = [];
            hashTable[aData.p].children.push(hashTable[aData.id]);
        }
    });
    return dataTree.length > 0 ? dataTree[0] : null;
};

const isColorByModel = (colorBy) => (colorBy === 'fitness' || colorBy === 'advance' || colorBy === 'flux');

export const getAntigenicSegmentsCount = modelId => {
    if (!modelId) return 0;
    const cnt = (modelId.match(/-/g) || []).length;
    return cnt ? cnt + 1 : cnt; //modelId.split('-').length;
};




export const getAntigenicSegments = (modelId, antigenicSegmentsCount) => {
    if (!modelId) return modelId;
    let strainPropagation = null;
    let refStrainPropagation = null;
    let collaboratingCenter = null;
    let assay = null;

    switch (antigenicSegmentsCount) {
        case 4: {
            [
                strainPropagation,
                refStrainPropagation,
                collaboratingCenter,
                assay,
            ] = modelId.split('-');
            break;
        }
        case 3: {
            [
                strainPropagation,
                collaboratingCenter,
                assay
            ] = modelId.split('-');
            break;
        }
        case 2: {
            [
                strainPropagation,
                collaboratingCenter,
            ] = modelId.split('-');
            break;
        }
    }
    return { strainPropagation, refStrainPropagation, collaboratingCenter, assay };

};

export const getAntigenicSegmentsByName = (modelId, segmentNames) =>
    (modelId || '').split('-').reduce((acc, value, index) => ({ ...acc, [segmentNames[index]]: value }), {});


export const getSegments = (modelIds, segmentNames) => {
    // console.log('[getSegments]', modelIds, segmentNames);
    const valueSet = (modelIds || []).reduce((acc, modelId) => {
        //const antigenicSegmentsCount = getAntigenicSegmentsCount(modelId);
        // const { collaboratingCenter, assay, strainPropagation, refStrainPropagation } = getAntigenicSegments(modelId, antigenicSegmentsCount);

        const { collaboratingCenter, assay, strainPropagation, refStrainPropagation } = getAntigenicSegmentsByName(modelId, segmentNames);
        if (collaboratingCenter) acc.collaboratingCenters.add(collaboratingCenter);
        if (assay) acc.assays.add(assay);
        if (strainPropagation) acc.strainPropagations.add(strainPropagation);
        if (refStrainPropagation) acc.refStrainPropagations.add(refStrainPropagation);
        return acc;
    }, {
        collaboratingCenters: new Set(),
        assays: new Set(),
        strainPropagations: new Set(),
        refStrainPropagations: new Set(),
    });
    // console.log(valueSet)
    const res = Object.keys(valueSet).reduce((acc, key) => {
        acc[key] = [...valueSet[key]].map(id => ({ id }));
        return acc;
    }, {});
    // console.log(modelIds);
    // console.log(res);
    return res;
};

const getMeasureScaleParamName = measure => measure && measure.scale && isPlainObject(measure.scale) ? Object.keys(measure.scale)[0] : undefined;
// function getTextMetrics(text, font) {
//     let canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement("canvas"));
//     let context = canvas.getContext("2d");
//     context.font = font;
//     let metrics = context.measureText(text);

//     let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
//     //let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
//     //console.log(metrics.width, fontHeight, actualHeight);
//     return { width: metrics.width, height: fontHeight };
// }

const getBiggerForSortTable = (a, b, type, asc) => {
    const nameA = type !== 'scale' ? a[type].toUpperCase() : (typeof a.scale === 'string') ? a.scale.toUpperCase() : '(parameterized)'.toUpperCase();
    const nameB = type !== 'scale' ? b[type].toUpperCase() : (typeof b.scale === 'string') ? b.scale.toUpperCase() : '(parameterized)'.toUpperCase();
    if (nameA < nameB) {
        return asc ? -1 : 1;
    }
    if (nameA > nameB) {
        return asc ? 1 : -1;
    }
    return 0;
};

// A simple debounce function
const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const degreesToRadians = (degrees) => degrees * (Math.PI / 180);

const subtractYears = (date, years) => {
    if (!(date instanceof Date)) return null; // Check if date is not an instance of Date
    const newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() - years);
    return newDate;
};

export const getDateFromString = dateString => dateString ? new Date(dateString) : null;

export const dateToString = date => date ? date.toISOString() : null;

export const dateToReduxDateFormat = date => dateToString(date);

export const reduxDateFormatToDate = rdate => getDateFromString(rdate);


export {
    emptyObject,
    daysToDate,
    dateToDays,
    monthFirstDate,
    monthLastDate,
    quarterFirstDate,
    quarterLastDate,
    yearFirstDate,
    prepareSelectedModels,
    yearLastDate,
    getAcademicYear,
    // runPromise,
    runAction,
    treePostOrder,
    propToString,
    // treePostOrderFilter,
    treePreOrder,
    transformUrlToParams,
    prepareParametersToExportUrl,
    minNumber,
    maxNumber,
    titleCase,
    arrayFromObjProps,
    getDefaultTrackingFromDate,
    getDefaultTrackingToDate,
    getPathogenType,
    logSum,
    getBiggerForSortTable,
    // getCladeIdLabel,
    isFunction,
    isNumeric,
    pick,
    prepareUrl,
    prepareParameters,
    getParametersObject,
    shouldFetch,
    isLoadedOrNA,
    checkTreeOrder,
    isNull,
    compare,
    stratify,
    getTrimmedText,
    isColorByModel,
    getTextMetrics,
    getMeasureScaleParamName,
    debounce,
    degreesToRadians,
    subtractYears
};
