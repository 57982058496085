import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';


import { getIsMobile } from '../../redux/selectors/statusSelector';
import VaccinesChartD3 from './d3/VaccinesChartD3';
import { getNodeWidth } from '../../functions/cssHelpers';

import { setComponentStatus } from '../../redux/actions/renderActions';

import { useEventListener } from 'usehooks-ts';
import { useDebouncedCallback } from '../../functions/customHooks';
import { getAllRefStrainsWithLabAndClade, selectedFerretStrainsSelector, sortedFerretStrainsSelector, vaccinesAntigenicCladeFrequenciesSelector, vaccinesDataSelector } from '../../redux/selectors/vaccinesSelector';
import { getTextMetrics } from '../../functions/functions';
import { setParameters } from '../../redux/actions/parametersActions';
// import { fetchRegionMultiplicities } from '../../actions/predictionsActions';

const styles = () => ({
    // root: {
    //     width: '100%',
    //     height: '100%',
       
    // },
    graph: {
        padding: '0px 20px 20px 20px',
        // height: 'fit-content',
        // width: 'fit-content',
        height: 'calc(100% - 20px)',
        // height: '100%',
        // width: '100%',
        // minHeight: '100%',
        // maxHeight: '100%',
        // overflow: 'overlay',

    },
    border: {
        border: 'solid 1px #c1c1c1' 
    },
    graphMobile: {
        padding: '2px 0px 20px 0px',
    },
    graphExport: {
        padding: 0,
        // boxSizing: 'border-box',
        height: '100%',
        // width: '2800px'
    },
});

const useStyles = makeStyles(styles);

const textFont = `10px 'Inter'`; 
const VaccinesChart = props => {


    const { exportMode, loading, hiddenMenu, isMobile, vaccinesData, vaccinesFerretRefStrains, width, frequencies } = props;


    // console.log('[VaccinesChart]', {vaccinesFerretRefStrains, frequencies, vaccinesData});
    const refStrainsCnt = useMemo(() => vaccinesFerretRefStrains?.length || 0, [vaccinesFerretRefStrains]);
    // console.log('[VaccinesChart]', { vaccinesFerretRefStrains, refStrainsCnt });
    // console.log('[VaccinesChart] frequencies', frequencies);
    const classes = useStyles();
    const _element = useRef();
    const parentRef = useRef();
    const componentId = 'VACCINES_CHART';
    const chartD3 = useRef(new VaccinesChartD3(componentId));

    const referenceStrains = useMemo(() => vaccinesData?.reduce((acc, elem) => {
        if (!acc[elem.strainIndex]) acc[elem.strainIndex] = { 
            refid: elem.refid, 
            name: elem.name, 
            lab: elem.lab, 
            textLength: getTextMetrics(elem.name, textFont).width,
            cladeTextLength: getTextMetrics(`${elem.antigenicCladeLabel} (${elem.lab})`, textFont).width,
            antigenicCladeLabel: elem.antigenicCladeLabel,
            strainIndex: elem.strainIndex
        };
        return acc;
    }, {}), [vaccinesData]);

    const strainIndexes = useMemo(() => Object.values(referenceStrains||{}).reduce((acc, strain) => {
        acc[`${strain.refid}_${strain.lab}`] = strain.strainIndex;
        return acc;
    }, {}), [referenceStrains]);

    const frequenciesStrains = useMemo(() => Object.entries(frequencies||{}).map(([strain_id, elem]) => {
        return {
            strain_id,
            ...elem,
            strainIndex: strainIndexes[strain_id]
        };
    }, {})
        .filter(d => d.strainIndex !== undefined)
        .sort((a, b) => a.strainIndex - b.strainIndex), [frequencies, strainIndexes]);

    // console.log('[VaccinesChart] referenceStrains', referenceStrains);

    const maxTextLength = useMemo(() => 
        Math.max(...Object.values(referenceStrains||{})
            .map(strain => Math.max(strain.textLength, strain.cladeTextLength))), 
    [referenceStrains]);

    const d3Props = useMemo(() => ({
        ...props,
        refStrainsCnt,
        referenceStrains,
        frequenciesStrains,
        maxTextLength: maxTextLength < 0 ? 0 : maxTextLength
    }), [props, maxTextLength, frequenciesStrains]);


    useEffect(() => {
        chartD3.current.setProps(d3Props);
    }, [d3Props]);

    const updateDimensions = () => {
      
        if (_element.current) {
            const mountNode = _element.current;
            const { parentNode } = mountNode;
            const _width = getNodeWidth(parentNode);
            const height = chartD3.current.getCalculatedHeight();
            const width = chartD3.current.getCalculatedWidth(_width);
            // console.log({_width, width, height});
            // console.log('updateDimensions', {_width, width, height});
            // console.log('parentNode', parentNode, _width);
            // let parent = parentNode;
            // let i = 1;
            // while (parent.parentNode && parent.parentNode.nodeName !== '#document') {
            // console.log(`parentNode ${i}`, parent.parentNode, getNodeWidth(parent.parentNode));
            // parent = parent.parentNode;
            // i++;
            // }
            chartD3.current.resizeComponent(width, height);
        }
    };

    const debouncedResize = useDebouncedCallback(() => {
        // console.log('debouncedResize', componentId);
        updateDimensions();
    }, 200);


    if (!exportMode) {
        useEventListener('resize', debouncedResize);
    }


  

    // Modify the initial useEffect to run when width/height are available
    useEffect(() => {
        const mountNode = _element.current;
        // Only initialize if we have valid dimensions
        //    const { parentNode } = mountNode;
    
       
        // chartD3.current.setProps(d3Props);
        chartD3.current.setMountNode(mountNode);
        chartD3.current.setHeight(0);
        chartD3.current.setWidth(0);
        // setChartDimensions();
        // chartD3.current.clearChart();
        chartD3.current.prepareGraphArea();
    }, []); 





    const isFirstRender = useRef(true);

    useEffect(() => {   
        // console.log('[VaccinesChart] useEffect [hiddenMenu, refStrainsCnt, maxTextLength]', {hiddenMenu, refStrainsCnt, maxTextLength, loading});
        chartD3.current.setProps(d3Props);
        if (loading) return;
        // if (isFirstRender.current) {
        //     isFirstRender.current = false;
        //     return;
        // }
        // console.log('[VaccinesChart] useEffect', {hiddenMenu, refStrainsCnt, maxTextLength});
        updateDimensions();
    }, [hiddenMenu, refStrainsCnt, maxTextLength, loading]);


    useEffect(() => {
        // console.log('[VaccinesChart] useEffect', {loading, vaccinesData});
        if (loading || !vaccinesData) {
            return;
        }
        // updateDimensions();
        chartD3.current.renderD3Component('vaccines', componentId);

    }, [loading, vaccinesData]);        


    // useEffect(() => {
    //     handleMouseLeaveAll(chartLayout);
    //     const value = activeLegendOption?.value;
    //     if (!isNil(value)) {
    //         handleMouseEnter(value, chartLayout);
    //     }
    // }, [activeLegendOption]);

    const style = exportMode
        ? classes.graphExport
        : isMobile
            ? classes.graphMobile
            : classes.graph;


    return (
        <div className={style} ref={parentRef} style={{width: width}}>
            {/* {title && <div className={classes.title}>{title}</div>} */}
            <svg className={`${classes.border} svg-bg`} id={componentId} ref={_element} />
        </div>
    );
};

// Mapping our Redux State to Props
VaccinesChart.propTypes = {
    loading: PropTypes.bool,
    lineage: PropTypes.string,
};



const mapStateToProps = (state) => {
    const { clades, cladesStatus } = state.cladeData;
    const { vaccineProtectionValues, 
        vaccinesDataStatus, vaccinesDefaultsStatus, vaccinesFrequenciesStatus, vaccinesPredictionsStatus } = state.vaccines;
    const { exportMode } = state.parameters;

    //  console.log('[VaccinesChart] mapStateToProps', {cladesStatus, vaccinesDataStatus, vaccinesDefaultsStatus});
    const loading = !(
        cladesStatus === 'loaded' && 
        vaccinesDataStatus === 'loaded' && 
        vaccinesDefaultsStatus === 'loaded' && 
        vaccinesFrequenciesStatus === 'loaded' && 
        vaccinesPredictionsStatus === 'loaded'
    );

    
    return ({
        clades,
        vaccineProtectionValues,
        cladesStatus,
        loading,
        exportMode,
        hiddenMenu: state.ui.hiddenMenu,
        isMobile: getIsMobile(),
        vaccinesData: vaccinesDataSelector(state),
        vaccinesFerretRefStrains: sortedFerretStrainsSelector(state), 
        cladesCnt: state.parameters.vaccinesRhos?.length || 0,
        frequencies: vaccinesAntigenicCladeFrequenciesSelector(state),
        width: state.parameters.width,
        // vaccinesFerretRefStrains,
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setComponentStatus,
    setParameters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaccinesChart);
