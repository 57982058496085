/* eslint-disable */
import {
    ZOOM_TREE_REQUEST,
    ZOOM_TREE_SUCCESS,
    ZOOM_TREE_ERROR,

    INIT_STRAIN_TREE_REQUEST,
    INIT_STRAIN_TREE_SUCCESS,
    INIT_STRAIN_TREE_ERROR,

    FETCH_RECALCULATED_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_SUCCESS,
    FETCH_RECALCULATED_TREE_ERROR,

    FETCH_SUBSET_TREE_REQUEST,
    FETCH_SUBSET_TREE_SUCCESS,
    FETCH_SUBSET_TREE_ERROR,

    FETCH_TREE_FREQS_REQUEST,
    FETCH_TREE_FREQS_SUCCESS,
    FETCH_TREE_FREQS_ERROR,

    FETCH_SELECTED_STRAIN_REQUEST,
    FETCH_SELECTED_STRAIN_SUCCESS,
    FETCH_SELECTED_STRAIN_ERROR,

    FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR,

    FETCH_VP_VALUES_REQUEST,
    FETCH_VP_VALUES_SUCCESS,
    FETCH_VP_VALUES_ERROR,

    FETCH_HUMAN_SEROLOGY_REQUEST,
    FETCH_HUMAN_SEROLOGY_SUCCESS,
    FETCH_HUMAN_SEROLOGY_ERROR,

    FETCH_CUSTOM_TREE_ATTRS_REQUEST,
    FETCH_CUSTOM_TREE_ATTRS_SUCCESS,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,

    FETCH_HUMAN_POOLS_SUCCESS,
    FETCH_GENOTYPE_DATA_SUCCESS,

    FETCH_STRAINS_LIST_REQUEST,
    FETCH_STRAINS_LIST_SUCCESS,
    FETCH_STRAINS_LIST_ERROR,

    FETCH_REFERENCE_STRAINS_REQUEST,
    FETCH_REFERENCE_STRAINS_SUCCESS,
    FETCH_REFERENCE_STRAINS_ERROR,

    RESET_REFERENCE_STRAINS,
    RESET_SESSION,
    RESET_VP_VALUES,
    RESET_HUMAN_SEROLOGY_DATA,

    SET_REFERENCE_STRAIN,
    SET_STRAINS_LIST,
    SET_STRAIN_SEARCH_STATUS,
    SET_COLOR_BY,

    SIGNOUT_REQUEST,
    FETCH_LINEAGE_ERROR,
    FETCH_BRANCH_NODES_SUCCESS,
    SET_BRANCH_NODES,
    SET_SELECTED_STRAIN,
    RESET_TREE

} from '../actions/actionTypes';

import { ZOOM_TYPES } from '../../config/consts';
import { emptyObject } from '../../functions/functions';

import deepFreeze from 'deep-freeze';
import { produce } from 'immer';

let treeDataInitialState = {};
export const setTreeDataInitialState = (state) => {
    treeDataInitialState = state;
};

const getZoomStack = (state, zoomNodeId, zoomType, resetLast, rootNodeId) => {
    // console.log(zoomNodeId, zoomType, resetLast, rootNodeId)
    let zoomNodeStack = [];
    if (zoomType === ZOOM_TYPES.ZOOM_IN || state.zoomNodeStack.length === 0) {

        zoomNodeStack = [...state.zoomNodeStack];
        if (rootNodeId && rootNodeId !== zoomNodeId) {
            zoomNodeStack.push(rootNodeId);
        }
        zoomNodeStack.push(zoomNodeId);
    } else if (resetLast || zoomType === undefined) {
        zoomNodeStack = state.zoomNodeStack.slice(0, state.zoomNodeStack.length - 1);
        if (rootNodeId && rootNodeId !== zoomNodeId) {
            zoomNodeStack.push(rootNodeId);
        }
        zoomNodeStack.push(zoomNodeId);
    } else if (zoomType === ZOOM_TYPES.ZOOM_OUT) {
        zoomNodeStack = [...state.zoomNodeStack];
        zoomNodeStack.pop();
    } else if (zoomType === ZOOM_TYPES.RESET) {
        zoomNodeStack = state.zoomNodeStack.slice(0, 1);
    }
    return zoomNodeStack;
};



const getTreeAttrs = (state, treeAttrs) => {
    if (emptyObject(treeAttrs)) {
        return state.treeAttrs;
    }
    return { ...state.treeAttrs, ...treeAttrs };
}


export const treeDataReducer = (state = treeDataInitialState, action) => 
    produce(state, (draft) => {
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return treeDataInitialState;
        }

        case INIT_STRAIN_TREE_REQUEST: {
            draft.treeDataStatus = 'loading';
            draft.vpValuesStatus = 'loading';
            break;
        }

        case FETCH_SELECTED_STRAIN_REQUEST: {
            const { searchId } = action.payload;
            draft.strainSearchStatuses[searchId] = 'loading';
            draft.additionalNodesStatus[searchId] = 'loading';
            draft.additionalNodes[searchId] = {};
            break;
        }

        case SET_SELECTED_STRAIN: {
            const { searchId } = action.payload;    
            draft.strainSearchStatuses[searchId] = 'none';
            draft.additionalNodes[searchId] = {};
            draft.additionalNodesStatus[searchId] = 'none';
            break;
        }


        case FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST: {
            const { searchId } = action.payload;
            draft.strainSearchStatuses[searchId] = 'loading';
            draft.additionalNodes.colorBy = {};
            draft.additionalNodesStatus.colorBy = 'loading';
            break;
        }


        case INIT_STRAIN_TREE_SUCCESS: {
            const { treeAttrs, strainCutoffDateMinMax, treeFreqs, visibleNodes, antigenicityScores, zoomType,
                highlightedStrainIds = {},
                additionalNodes = {},
                parameters: { zoomNodeId, rootNodeId }
            } = action.payload;

            draft.treeDataStatus = 'loaded';
            draft.vpValuesStatus = 'loaded';
            draft.treeAttrs = getTreeAttrs(state, treeAttrs);
            draft.treeFreqs = treeFreqs;
            draft.visibleNodes = visibleNodes;
            draft.highlightedStrainIds = highlightedStrainIds;
            draft.zoomNodeStack = getZoomStack(state, zoomNodeId, zoomType, true, rootNodeId);
            draft.zoomType = zoomType;
            // Only update strainCutoffDateMinMax if it has changed
            if (!draft.strainCutoffDateMinMax || 
                draft.strainCutoffDateMinMax.min !== strainCutoffDateMinMax.min || 
                draft.strainCutoffDateMinMax.max !== strainCutoffDateMinMax.max) {
                draft.strainCutoffDateMinMax = strainCutoffDateMinMax;
            }
            draft.tcellAntigenicityScores = antigenicityScores ? antigenicityScores.scores : {};
            draft.tcellAntigenicityScoresStatus = antigenicityScores ? 'loaded' : 'none';
            draft.rootNodeId = rootNodeId;
            draft.additionalNodes.subset = additionalNodes.subset;
            draft.additionalNodesStatus.subset = 'loaded';  
            break;
        }


        case RESET_VP_VALUES: {
            draft.vpValuesStatus = 'none';
            break;
        }

        case FETCH_VP_VALUES_REQUEST: {
            draft.vpValuesStatus = 'loading';
            draft.treeDataStatus = 'loading';
            break;
        }
        case FETCH_VP_VALUES_SUCCESS: {
            const { visibleNodes, treeAttrs } = action.payload;
            draft.treeDataStatus = 'loaded';
            draft.vpValuesStatus = 'loaded';
            draft.treeAttrs = getTreeAttrs(draft, treeAttrs);
            draft.visibleNodes = visibleNodes;
            break;
        }

        case FETCH_VP_VALUES_ERROR: {
            //console.log(action.payload);
            draft.treeDataStatus = 'error';
            draft.vpValuesStatus = 'error';
            break;
        }
        case FETCH_REFERENCE_STRAINS_REQUEST: { 
            draft.referenceStrainNodesStatus = 'loading';
            break;
        }
        case FETCH_REFERENCE_STRAINS_SUCCESS: {
            const { nodes } = action.payload;
            draft.referenceStrainNodesStatus = 'loaded';
            draft.referenceStrainNodes = nodes;
            break;
        }
        case RESET_REFERENCE_STRAINS: {
            draft.referenceStrainNodes = {};
            draft.referenceStrainNodesStatus = 'none';
            break;
        }

        case FETCH_REFERENCE_STRAINS_ERROR: {
            draft.referenceStrainNodesStatus = 'error';
            break;
        }

        case FETCH_RECALCULATED_TREE_SUCCESS: {
            const { visibleNodes, treeAttrs } = action.payload;
            if (!visibleNodes || !treeAttrs) {
                draft.treeDataStatus = 'loaded';
                break;
            }   
            draft.treeAttrs = getTreeAttrs(draft, treeAttrs);
            draft.visibleNodes = visibleNodes;
            draft.treeDataStatus = 'loaded';
            draft.vpValuesStatus = 'loaded';
            break;
        }

        case FETCH_CUSTOM_TREE_ATTRS_SUCCESS:
        {
            const { additionalNodes } = action.payload;
            draft.additionalNodes.colorBy = additionalNodes.colorBy;
            draft.additionalNodesStatus.colorBy = 'loaded';
            draft.treeDataStatus = 'loaded';
            draft.vpValuesStatus = 'loaded';
            break;
        }

        case FETCH_SELECTED_STRAIN_SUCCESS: {
            const { noDataReason, searchId, additionalNodes } = action.payload || {};
            if (searchId) {
                draft.strainSearchStatuses[searchId] = noDataReason || 'found';
                draft.additionalNodesStatus[searchId] = 'loaded';
                draft.additionalNodes[searchId] = additionalNodes[searchId];
            }
            break;
        }

        case FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS: {
            const { noDataReason, searchId, additionalNodes } = action.payload || {};
            const status = noDataReason ? (noDataReason || 'not_found') : 'found';
            if (searchId) {
                draft.strainSearchStatuses[searchId] = status;
                draft.additionalNodesStatus[searchId] = 'loaded';
                draft.additionalNodes[searchId] = additionalNodes[searchId];
                draft.strainSearchStatus = status; //TODO: remove or check whether needed
            }
         
            if (additionalNodes?.colorBy) {
                draft.additionalNodes.colorBy = additionalNodes.colorBy;
                draft.additionalNodesStatus.colorBy = 'loaded';
            }
            break;
            
        }

        case FETCH_BRANCH_NODES_SUCCESS:{
            const { additionalNodes } = action.payload;
            draft.additionalNodes.branch = additionalNodes.branch;
            draft.additionalNodesStatus.branch = 'loaded';
            break;          
        }

        case FETCH_TREE_FREQS_SUCCESS: {
            const { treeFreqs } = action.payload;
            draft.treeFreqs = treeFreqs;
            // draft.treeDataStatus = 'loaded';
            draft.vpValuesStatus = 'loaded';
            break;
        }
        case FETCH_SUBSET_TREE_ERROR:
        case FETCH_TREE_FREQS_ERROR:
        case INIT_STRAIN_TREE_ERROR:
        case FETCH_RECALCULATED_TREE_ERROR:
        // case FETCH_VISIBLE_NODES_ERROR:
        case FETCH_LINEAGE_ERROR: {
            draft.visibleNodes = {};
            draft.treeDataStatus = 'error';
            draft.vpValuesStatus = 'error';
            break;
        }
        case FETCH_SUBSET_TREE_REQUEST:
        // case FETCH_TREE_FREQS_REQUEST:
        case ZOOM_TREE_REQUEST:
        case FETCH_RECALCULATED_TREE_REQUEST: {
            Object.keys(draft.additionalNodesStatus).forEach(attr => {
                draft.additionalNodesStatus[attr] = 'none';
            });
            Object.keys(state.strainSearchStatuses).forEach(attr => {
                draft.strainSearchStatuses[attr] = 'none';
            });
            draft.treeDataStatus = 'loading';
            // draft.vpValuesStatus = 'loading';
            break;
        }

        case FETCH_TREE_FREQS_REQUEST: {
            // draft.treeDataStatus = 'loading';
            draft.vpValuesStatus = 'loading';
            break;
        }
        

        case ZOOM_TREE_SUCCESS: {
            const { visibleNodes, zoomType, treeAttrs, treeFreqs, highlightedStrainIds, parameters } = action.payload;
            const { zoomNodeId } = parameters;
            draft.treeDataStatus = 'loaded';
            draft.vpValuesStatus = 'loaded';
            draft.visibleNodes = visibleNodes;
            draft.treeFreqs = treeFreqs;
            draft.treeAttrs = getTreeAttrs(draft, treeAttrs);
            draft.zoomNodeStack = getZoomStack(draft, zoomNodeId, zoomType);
            draft.zoomType = zoomType;
            draft.highlightedStrainIds = highlightedStrainIds || {};
            break;
        }

        case ZOOM_TREE_ERROR: {
            draft.treeDataStatus = 'error';
            draft.vpValuesStatus = 'error';
            break;
        }

        case FETCH_SELECTED_STRAIN_ERROR:
        case FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR: {
            const { searchId, error } = action.payload;
            if (searchId) {
                draft.strainSearchStatuses[searchId] = error.map(({code})=> code).join(','); /*'error'*/;
                draft.additionalNodesStatus[searchId] = 'loaded'; //TODO: check whether 'error' status is better that 'loaded'
            }
            break;          
        }

        case FETCH_SUBSET_TREE_SUCCESS: {
            const { visibleNodes, treeAttrs, additionalNodes, subsetTreeStatus, highlightedStrainIds } = action.payload;
            const status = Object.keys(visibleNodes).length === 0 ? 'NO_DATA' : '';
            const _treeAttrs = getTreeAttrs(draft, treeAttrs);

            draft.additionalNodes.subset = additionalNodes.subset;
            // console.log('[FETCH_SUBSET_TREE_SUCCESS], additionalNodes.subset = ', Object.keys(additionalNodes.subset || {}).length)
            draft.additionalNodesStatus.subset = 'loaded';  
            draft.treeAttrs = _treeAttrs;
            draft.visibleNodes = visibleNodes;
            draft.treeDataStatus = 'loaded';
            // draft.vpValuesStatus = 'loaded';
            // draft.subsetTreeStatus = subsetTreeStatus || status;
            draft.highlightedStrainIds = highlightedStrainIds || {};
            break;
        }

        case SET_REFERENCE_STRAIN: {
            const { searchId } = action.payload;
            if (searchId) {
                draft.strainSearchStatuses[searchId] = 'none';
            }

            draft.additionalNodesStatus.colorBy = 'none';
            draft.additionalNodes.colorBy = {};
            break;
        }

        case SET_STRAIN_SEARCH_STATUS: {
            const { strainSearchStatus, searchId } = action.payload;
            if (searchId) {
                draft.strainSearchStatuses[searchId] = strainSearchStatus;
            }
            break;
        }
        case FETCH_STRAINS_LIST_REQUEST: {
            const { searchId } = action.payload;
            if (searchId) {
                draft.strainsListStatuses[searchId] = 'loading';
            }
            draft.strainsListStatus = 'loading';
            break;
        }
        case FETCH_STRAINS_LIST_SUCCESS:
        case SET_STRAINS_LIST: {
            const { strainsList, searchId } = action.payload;
            if (searchId) {
                draft.strainsListStatuses[searchId] = strainsList && strainsList.length > 0 ? 'loaded' : 'nodata';
                draft.strainsLists[searchId] = strainsList ? strainsList : undefined;
            }
            draft.strainsListStatus = 'loaded';
            break;
        }

        case FETCH_STRAINS_LIST_ERROR: {
            const { searchId } = action.payload;
            
            if (searchId) {
                draft.strainsListStatuses[searchId] = 'error';
                draft.strainsLists[searchId] = null;
            }
            draft.strainsListStatus = 'error'; //TODO: check whether needed
            break;  
        }           

        case FETCH_GENOTYPE_DATA_SUCCESS: {
            const { parentNodes } = action.payload;
            //console.log(parentNodes)
            draft.treeAttrs = getTreeAttrs(draft, parentNodes);
            break;
        }
        case FETCH_HUMAN_POOLS_SUCCESS:
        case RESET_HUMAN_SEROLOGY_DATA: {                           
            draft.additionalNodesStatus.colorBy = 'refetchNeeded';
            draft.additionalNodes.colorBy = {};
            break;
        }
        case FETCH_HUMAN_SEROLOGY_REQUEST:
        case FETCH_CUSTOM_TREE_ATTRS_REQUEST:{
            draft.additionalNodes.colorBy = {}
            draft.additionalNodesStatus.colorBy = 'loading';
            break;
        }

        case FETCH_HUMAN_SEROLOGY_SUCCESS: {
            const { additionalNodes } = action.payload;
            draft.additionalNodes.colorBy = additionalNodes.colorBy;
            draft.additionalNodesStatus.colorBy = 'loaded';
            break;
        }
        case FETCH_HUMAN_SEROLOGY_ERROR:
        case FETCH_CUSTOM_TREE_ATTRS_ERROR: {
            draft.additionalNodes.colorBy = {};
            draft.additionalNodesStatus.colorBy = 'error';
            break;
        }
        // Reset additional nodes after color by change
        case SET_COLOR_BY: {
            draft.additionalNodesStatus.colorBy = 'none';
            draft.additionalNodes.colorBy = {};
            break;
        }

        case SET_BRANCH_NODES: {
            draft.additionalNodesStatus.branch = 'none';        
            draft.additionalNodes.branch = {};
            break;
        }

        case RESET_TREE: {
            draft.treeDataStatus = 'none';
            draft.vpValuesStatus = 'none';
            draft.visibleNodes = {};
            draft.treeAttrs = {};
            draft.zoomNodeStack = [];
            draft.zoomType = null;
            break;
        }
        
        default:
            break;
    }
});
