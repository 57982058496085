import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AntigenicOptions from '../../components/Antigenic/AntigenicOptions';
import AntigenicData from '../../components/Antigenic/AntigenicData';
import AntigenicDetails from '../../components/Antigenic/AntigenicDetails/AntigenicDetails';
import ParametersInfo from '../../components/ParametersInfo/ParametersInfo';
import ExportableComponent from '../Export/ExportableComponent';
import appConfig from '../../config/appConfig';
import HamburgerIcon from '../../components/SvgIcons/HamburgerIcon';
import { fetchAntigenicModel, fetchAntigenicRawModel, selectAntigenicValues, setAntigenicHiddenAlphaClade, setAntigenicHiddenRhoClade, setParameters, unhideAlphaClade, unhideRhoClade } from '../../redux/actions/antigenicActions';
import { getMeasures } from '../../redux/selectors/metadataSelector';
import { shouldFetch } from '../../functions/functions';
import { RENDER_STATUS } from '../../config/consts';
import { setHiddenMenu } from '../../redux/actions/uiActions';
import { styles } from './styles';
import ScalesLegend from '../../components/ColorLegend/Legends/ScalesLegend';
import { fetchClades } from '../../redux/actions/cladeActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import { dynamicStyles, useWindowSize } from '../../assets/GlobalStyles/dynamicStyles';
import { getIsMobile } from '../../redux/selectors/statusSelector';

const Antigenic = (props) => {
    const { lineageStatus, lineage, antigenicModelId, exportMode,
        unhideAlphaClade, unhideRhoClade,
        antigenicDataType, antigenicTiterType, antigenicModelStatus, setHiddenMenu, antigenicModelsStatus,
        antigenicRawModelStatus,
        cladesStatus,
        hiddenMenu,
        renderStatus, menuRight, fetchMeasureScalesDomains,  dataLoaded, antigenicDataLoaded,
        fetchClades, fetchAntigenicModel, fetchDetailsModel, setAntigenicHiddenRhoClade, setAntigenicHiddenAlphaClade,
        selectAntigenicValues,
    } = props;

    const [deleted, setDeleted] = useState([]);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [cellInfo, setCellInfo] = useState();
    const classes = styles();
    const { width } = useWindowSize();
    const isMobile = getIsMobile();

    console.log('isMobile', isMobile);
    useEffect(() => {
        return () => {
            setDetailsOpen(false);
            setDeleted([]);
            setCellInfo(null);
        };
    }, []);

    //Requred to load all scales for titer drop values
    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        fetchMeasureScalesDomains({ lineage, colorBy: 'antigenic' });
    }, [lineageStatus]);

    useEffect(() => {
        // console.log(`useEffect lineageStatus = ${lineageStatus}, antigenicModelsStatus = ${antigenicModelsStatus}`);
        if (lineageStatus !== 'loaded') return;
        if (shouldFetch(cladesStatus)) fetchClades({ lineage });
        if (!antigenicDataLoaded) initAntigenicData();
    });

    const setDeletedClades = (clade) => {
        const newDeleted = {
            type: 'clade',
            id: clade
        };
        setDeleted([...deleted, newDeleted]);
        setAntigenicHiddenAlphaClade(clade);
    };

    const setDeletedRhos = (rho) => {
        const newDeleted = {
            type: 'rho',
            id: rho
        };
        setDeleted([...deleted, newDeleted]);
        setAntigenicHiddenRhoClade(rho);
    };

    const undoDeleting = () => {
        if (deleted.length === 0)
            return;
        const lastDeleted = deleted[deleted.length - 1];
        if (lastDeleted.type === 'rho')
            unhideRhoClade(lastDeleted.id);
        else
            unhideAlphaClade(lastDeleted.id);
        setDeleted(current =>
            current.filter(element => {
                return element.id !== lastDeleted.id;
            })
        );
    };


    const initAntigenicData = () => {
        if (cladesStatus !== 'loaded' || (!exportMode && antigenicModelsStatus !== 'loaded'))
            return;

        if (shouldFetch(antigenicModelStatus))
            fetchAntigenicModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType });

        if (shouldFetch(antigenicRawModelStatus))
            fetchDetailsModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType });
    };

    const getParameters = () => {
        const pars = [
            { name: 'Lineage', value: lineage },
            { name: 'Model', value: antigenicModelId },
            { name: 'Titer', value: antigenicTiterType },
            { name: 'Data type', value: antigenicDataType },
        ];
        return pars;
    };

    const handleHideClick = () => {
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    };

    const pars = getParameters();

    const matrixCellHandleClick = (rho, alpha) => {
        if (!detailsOpen) {
            selectAntigenicValues({ rho, alpha });
            setDetailsOpen(true);
        }
    };

    const matrixCellHandleMouseOver = (rho, alpha, titer) => {
        if (!detailsOpen) {
            setCellInfo({ rho, alpha, titer });
        }
    };

    const detailsCloseHandleClick = () => {
        selectAntigenicValues({});
        setDetailsOpen(false);
    };

    return (
        <>
            {!exportMode && (
                <div style={dynamicStyles(isMobile).root}>
                    {detailsOpen && <AntigenicDetails detailsCloseHandleClick={detailsCloseHandleClick} />}
                    <Grid container justifyContent="space-between" className={classes.container}>
                        {!menuRight &&
                            <Grid className={classes.antigenicSidebarLeft}>
                                {!hiddenMenu ?
                                    <AntigenicOptions
                                        undoDeleting={undoDeleting}
                                        handleHideClick={handleHideClick}
                                        cellInfo={cellInfo}
                                    />
                                    :
                                    <IconButton className={classes.expandButton} onClick={handleHideClick}>
                                        <HamburgerIcon />
                                    </IconButton>
                                }
                            </Grid>
                        }
                        <Grid className={classes.item}>
                            <div style={dynamicStyles(isMobile).space}>
                                <ExportableComponent filename="antigenic">
                                    <AntigenicData
                                        dataInitialized={dataLoaded}
                                        setDeletedRows={setDeletedClades}
                                        setDeletedColumns={setDeletedRhos}
                                        matrixCellHandleClick={matrixCellHandleClick}
                                        matrixCellHandleMouseOver={matrixCellHandleMouseOver}
                                    />
                                </ExportableComponent>
                            </div>
                        </Grid>
                        {menuRight &&
                            <Grid  className={classes.antigenicSidebarRight}>
                                {!hiddenMenu ?
                                    <AntigenicOptions
                                        undoDeleting={undoDeleting}
                                        handleHideClick={handleHideClick}
                                        cellInfo={cellInfo}
                                    />
                                    :
                                    <IconButton className={classes.expandButton} onClick={handleHideClick}>
                                        <HamburgerIcon />
                                    </IconButton>
                                }
                            </Grid>
                        }
                    </Grid>
                </div>
            )}
            {exportMode && dataLoaded && (
                <Grid container className={classes.containerExport}>
                    {detailsOpen && <AntigenicDetails detailsCloseHandleClick={detailsCloseHandleClick} />}
                    {!detailsOpen && 
                    <Grid size={8} className={classes.item}>
                        <AntigenicData 
                            dataInitialized={dataLoaded} 
                            matrixCellHandleClick={matrixCellHandleClick}
                            matrixCellHandleMouseOver={matrixCellHandleMouseOver}
                        />
                    </Grid>
                    }
                    <Grid size={4}>
                        <div className={classes.legend}>
                            <ParametersInfo parameters={pars} />
                            <div className={classes.dataLegend}>
                                <ScalesLegend
                                    scaleId="antigenic"
                                    measureName="antigenic"
                                    ticks={3}
                                    title="Titer"
                                    precision={2}
                                />
                                <ScalesLegend
                                    scaleId="alphaY"
                                    measureName="alphaY"
                                    ticks={3}
                                    title="Clade frequency"
                                    precision={3}
                                />
                                <ScalesLegend
                                    scaleId="rhoR"
                                    measureName="rhoR"
                                    ticks={3}
                                    title="Immune cohort weight"
                                    precision={2}
                                />
                            </div>
                        </div>
                    </Grid>

                </Grid>
            )}
            {exportMode === true && renderStatus === RENDER_STATUS.DONE && <div id="exportDone" />}
        </>
    );
};


Antigenic.propTypes = {
    fetchLineage: PropTypes.func,
    fetchAntigenicModel: PropTypes.func,
    fetchDetailsModel: PropTypes.func,
    // fetchAntigenicModelsList: PropTypes.func,
    lineage: PropTypes.string,
    antigenicModelId: PropTypes.string,
    antigenicDataType: PropTypes.string,
    antigenicTiterType: PropTypes.string,
    lineageStatus: PropTypes.string,
    cladesStatus: PropTypes.string,
    // antigenicCladesStatus: PropTypes.string,
    antigenicModelStatus: PropTypes.string,
    exportMode: PropTypes.bool,
    renderStatus: PropTypes.string,
    // detailsOpen: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const {
        lineage,
        antigenicModelId,
        antigenicDataType,
        antigenicTiterType,
        exportMode,
        freqCategory,
        zoomNodeId,
        strainSubset,
        // binMethod,
        // binCnt,
    } = state.parameters;
    const { antigenicModelStatus, antigenicRawModelStatus, antigenicCladesStatus /*, detailsOpen*/ } = state.antigenic;
    const { cladesStatus } = state.cladeData;

    const dataLoaded = (cladesStatus === 'loaded' && antigenicModelStatus === 'loaded' && antigenicRawModelStatus === 'loaded');
    const treeDataLoaded = cladesStatus === 'loaded';
    const antigenicDataLoaded = (antigenicModelStatus === 'loaded' && antigenicRawModelStatus === 'loaded');
    const measures = getMeasures(state);

    return {
        lineage: lineage || appConfig.default.lineage,
        antigenicModelId, //modelId.split('-').length === 4 ? modelId : appConfig.default.antigenicModelId,
        antigenicDataType: antigenicDataType || appConfig.default.antigenicDataType,
        antigenicTiterType: antigenicTiterType || appConfig.default.antigenicTiterType,
        freqCategory,
        zoomNodeId,
        strainSubset,
        dataLoaded,
        treeDataLoaded,
        antigenicDataLoaded,
        menuRight: state.user.menuRight,
        measures,
        lineageStatus: state.lineages.lineageStatus,
        lineagesStatus: state.lineages.lineagesStatus,
        antigenicModelsStatus: state.models.modelsStatus.antigenic,
        antigenicModelStatus,
        antigenicRawModelStatus,
        antigenicCladesStatus,
        cladesStatus,
        exportMode,
        renderStatus: state.render.renderStatus,
        hiddenMenu: state.ui.hiddenMenu,
        // detailsOpen,
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchAntigenicModel: (payload) => dispatch(fetchAntigenicModel(payload)),
    fetchDetailsModel: (payload) => {
        dispatch(setParameters(payload));
        dispatch(fetchAntigenicRawModel(payload));
    },
    unhideAlphaClade: (payload) => dispatch(unhideAlphaClade(payload)),
    unhideRhoClade: (payload) => dispatch(unhideRhoClade(payload)),
    setParameters: (payload) => dispatch(setParameters(payload)),
    //fetchMeasureScalesDomains: (payload) => dispatch(fetchMeasureScalesDomains(payload)),
    setHiddenMenu: (payload) => dispatch(setHiddenMenu(payload)),
    fetchClades: payload => dispatch(fetchClades(payload)),
    setAntigenicHiddenRhoClade: payload => dispatch(setAntigenicHiddenRhoClade(payload)),
    setAntigenicHiddenAlphaClade: payload => dispatch(setAntigenicHiddenAlphaClade(payload)),
    selectAntigenicValues: payload => dispatch(selectAntigenicValues(payload)),
    fetchMeasureScalesDomains: payload => dispatch(fetchMeasureScalesDomains(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Antigenic);
// export { AntigenicExport };
